import {memo, useEffect, useState} from "react";
import {useFavourite} from "../store/hooks/useFavourite";
import icons from "../constants/icons";
import "../screens/product.scss"
import {useTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import _ from "lodash";
import {NewCard} from "../components/card/newCard";


const FavouritesScreen = () =>{

	const {t} = useTranslation()
	const favouriteHook = useFavourite()
	const [loader,setLoader]=useState(false)

	useEffect(()=>{
		favouriteHook.getFavourites(setLoader)
	},[])
	return <Container className={"main-container"}>

		{
			favouriteHook?.Favourites.length > 0  &&
				<Container>
					<div className="products_container">
						<div className="prodacts">
							<div className="prodacts-container">
								{
									favouriteHook?.Favourites.length > 0 ?
										<>

											{/*<ProductList products={productsHook?.SelectedProducts?.products}/>*/}
											<div className="prodacts-wrap">
												{
													_.map(favouriteHook?.Favourites,(v,k)=>{
														return (
															<NewCard
																key={k} v={v} onClick={_=>{
																favouriteHook.removeFavourite(v.product_id,k).then(()=>favouriteHook.getFavourites(setLoader))
															}}/>
														)
													})
												}

											</div>


										</>:
										<div style={{flex:1,justifyContent:'center',alignItems:'center'}}>
											<img src={loader?icons.spinner:icons.nothingFound} style={{height:50,width:50}} alt=""/>
											<span>{t("კერძები არ მოიძებნა")}</span>
										</div>
								}
							</div>
						</div>

					</div>
				</Container>
		}
	</Container>
}
export default memo(FavouritesScreen)