import {GET_SPACES, GET_ORDERS, CART, ORDER_MODAL, UPDATE_ORDERS, SET_COMMENT,} from "./orderTypes";

const initialState={
    spaces:[],
    orders:[],
    cart:window.localStorage.getItem('cart') ? JSON.parse(window.localStorage.getItem('cart')) : [],
    orderModal:false,
    comment:''
};

const orderReducer = (state=initialState, action)=>{
    switch (action.type){
        case SET_COMMENT:
            return {...state,comment:action.payload}
        case GET_SPACES:
            return {...state,spaces: [...action.payload]}
        case GET_ORDERS:
            return {...state,orders: [...action.payload]}
        case UPDATE_ORDERS:
            return {...state,orders: action.payload}
        case CART:
            window.localStorage.setItem('cart',JSON.stringify( action.payload))
            return {...state,cart: action.payload}
        case ORDER_MODAL:
            return {...state,orderModal: action.payload}
        default: return {...state}
    }
}

export default orderReducer;
