import Client from "../Client";
import Config from "../config/Config";
import {GET_ORDERS, GET_SPACES} from "../reducers/orders/orderTypes";
import {API} from "../index";
import {DELETE_FAVOURITE} from "../reducers/favourites/favouriteTypes";
import {GetFavourites} from "../reducers/favourites/favouriteActions";
const GetSpaces  = ({loader}) =>async (dispatch)=>{
	 Client.get({url: Config.ORDER.SPACES,loader:loader})
		 .then(response=>{
			 dispatch({
				 type:GET_SPACES,
				 payload:response.status?response.data:[]
			 })
 		 })
}
const GetOrders  = ({loader}) =>async (dispatch)=>{
	Client.get({url: Config.USER.ORDERS,loader:loader})
		.then(response=>{
			dispatch({
				type:GET_ORDERS,
				payload:response.status?response.data:[]
			})
		})
}
const getOrderDetails=({id})=>{
	return Client.get({url:Config.USER.ORDER_DETAILS.replace("{id}",id)})
}
const CreateOrder = (data,loader) =>{
	return  Client.post({url: Config.USER.CREATE_ORDER,loader:loader,data:data})
}
const DeleteOrder = (id)=>{
	return Client.get({url:Config.USER.DELETE_ORDER.replace("{id}",id)})
}
export  {
	GetSpaces,
	CreateOrder,
	GetOrders,
	DeleteOrder,
	getOrderDetails
}