
export const useStorage = () =>{
    async function get(key) {
        return localStorage.getItem(key);

    }
    async function set(key,value) {
        await localStorage.setItem(key, value);
    }
    async function remove(key) {
        await localStorage.removeItem(key);
    }
    return {get,set,remove}
}
