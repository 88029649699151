import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import  './signInModal.scss';

import {utils} from "../../../utils";
import {API} from "../../../store";
import Toast from "../../../store/helpers/toast"
import {UseOTP, UseSignIn} from "../../../store/hooks";
import {useTranslation} from "../../../store/i18n/i18n";
import {Link} from "react-router-dom"
import {COLORS} from "../../../constants"
export const SignUpModal = ({ onCancel,onSuccess,  ...props }) => {

    const {i18n,t}= useTranslation()
    const signInModal = UseSignIn();
    const otpModal = UseOTP()
    const [loader,setLoader]=useState(false)

    const [firstName,setFirstName]=useState("");
    const [firstNameError, setFirstNameError] = useState("");

    const [lastName,setLastName]=useState("");
    const [lastNameError, setLastNameError] = useState("");

    const [idNumber,setIdNumber]=useState("");
    const [idNumberError, setIdNumberError] = useState("");

    const [phone,setPhone]=useState("");
    const [phoneError, setPhoneError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [passType, setPassType] = useState({
        pass1:'password',
        pass2:'password'
    });
    const isEnabledSignIn=()=>{
        return (
            firstName !== "" && firstNameError===""   &&
            lastName !=="" && lastNameError ==="" &&
/*
            idNumber !=="" && idNumberError ==="" &&
*/
            phone !=="" && phoneError ==="" &&
            password !=="" && passwordError ===""
            //&&rePassword !=="" && rePasswordError ===""
        )
    }
    const togglePassType=(pass)=>{
        if(pass === 'pass1'){
            setPassType(passType.pass1 === 'text'?{...passType,pass1:'password'}:{...passType,pass1:'text'})
        }else{
            setPassType(passType.pass2 === 'text'?{...passType,pass2:'password'}:{...passType,pass2:'text'})
        }

    }
    const onRegister=(otp,showOtpModal=true)=>{
        let userData = {
            firstName:firstName,
            lastName:lastName,
            idNumber:idNumber,
            phone:phone,
            password:password
        }

        if(otp?.smsCode){
            userData={...userData,otp:otp.smsCode}
        }
        if(isEnabledSignIn()){
            API.Guest.SignUp({
                data:userData,
                loader:setLoader
            }).then(response=>{
                if(response.status){
                    onCancel()
                    otp.otpHide()
                    signInModal.open();
                    Toast.show({
                        type:'success',
                        text1:t("რეგისტრაცია წარმატებით დასრულდა!")
                    })
                }else{
                    switch (response.data.key){
                        case 'idNumber':
                            setIdNumberError(response.data.msg)
                            break;
                        case 'phone':
                            setPhoneError(response.data.msg)
                            break;
                        case 'otp':
                            if(response.data.msg==="otp"){
                                if(showOtpModal){
                                    otpModal.open({
                                        onSuccess:(smsCode,error,hide)=>{
                                            onRegister({
                                                smsCode:smsCode,
                                                otpError:error,
                                                otpHide:hide
                                            });

                                        },
                                        onResend:(callback)=>{
                                            onRegister(null,false)
                                            callback(60,"")
                                        }
                                    })
                                }

                            }else{
                                Toast.show({
                                    text1:response.data.msg
                                })
                            }
                            break;
                        default: Toast.show({
                            type:'error',
                            text1: response.data.msg,
                            autoHide:true,
                            visibilityTime:3000
                        })
                    }
                }
            })
            //
        }
    }
  return (
    <Modal contentClassName="sign-up-modal-restyle" onHide={onCancel} {...props}  draggable >
      <Modal.Header closeButton>
        <Modal.Title>{t("რეგისტრაცია")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e)=>{
          e.preventDefault();
           onRegister()
        }} >
            <Form.Group className="form-box">
                <div className="input-label">
                    <Form.Control value={firstName} isInvalid={firstNameError!==""} type="text" onChange={e=>{
                        let value= e.target.value;
                        utils.validateEmpty(value,setFirstNameError)
                        setFirstName(value)
                    }}/>
                    <Form.Label>{t("სახელი")}</Form.Label>
                </div>
                <Form.Control.Feedback type="invalid">{t(firstNameError)}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-box">
                <div className="input-label">
                    <Form.Control value={lastName} isInvalid={lastNameError!==""} type="text" onChange={e=>{
                        let value= e.target.value;
                        utils.validateEmpty(value,setLastNameError)
                        setLastName(value)
                    }}/>
                    <Form.Label>{t("გვარი")}</Form.Label>
                </div>
                <Form.Control.Feedback type="invalid">{t(firstNameError)}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-box">
                <div className="input-label">
                    <Form.Control value={idNumber} isInvalid={idNumberError!==""} type="text" onChange={e=>{
                        let value= e.target.value;
                        utils.validateEmpty(value,setIdNumberError)
                        setIdNumber(value)
                    }}/>
                    <Form.Label>{t("პირადი ნომერი")} ({t("არასავალდებულო")})</Form.Label>
                </div>
                <Form.Control.Feedback type="invalid">{t(idNumberError)}</Form.Control.Feedback>
            </Form.Group>

          <Form.Group className="form-box">
              <div className="input-label">
                  <Form.Control value={phone || phoneError} isInvalid={phoneError!==""} type="number" onChange={e=>{
                      let value= e.target.value;
                      utils.validatePhone(value,setPhoneError)
                      setPhone(value)
                  }}/>
                  <Form.Label>{t("მობილური")}</Form.Label>
              </div>
            <Form.Control.Feedback type="invalid">{t(phoneError)}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-box">
              <div className="input-label">
                  <Form.Control value={password} type={passType.pass1}  isInvalid={passwordError !==""} onChange={e=>{
                      let value= e.target.value;
                      utils.validatePassword(value,setPasswordError)
                      setPassword(value)
                  }}/>
                  <Form.Label>{t("პაროლი")}</Form.Label>
                  <div className={`toggle-password ${passType.pass1==='text'?'active':'hide'}`} onClick={()=>{togglePassType('pass1')}}/>
                  {/*<Icon
                style={{
                  position:'absolute',
                  right:"25px",
                 }}
                Icon={!showPassword?EyeSlashIcon:EyeIcon}
                height="20px"
                native
                width="20px"
                onClick={()=>setShowPassword(!showPassword)}
              />*/}
              </div>

            <div style={{color:'red',fontSize:'0.875em'}}>
              {passwordError}
            </div>
          </Form.Group>
          <Stack
              className="btn_box"
            direction={'horizontal'}
            style={{ justifyContent: 'flex-end',marginTop:"1rem" }}
            gap={4}
          >

            <Button variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
              <Button variant={'success'} disabled={!isEnabledSignIn()} type={"submit"}>{t("რეგისტრაცია")}</Button>
          </Stack>
            <Form.Group className="form-box pt-3" >
                <div style={{
                    fontSize:'0.875em',
                }}>
                    { t(`"რეგისტრაცია" ღილაკზე დაჭერით, თქვენ ეთანხმებით`) }
                    <Link to={`/${i18n.language}/rules`} target="_blank" style={{color:COLORS.primary}}> {t(`"გამოყენების პირობებს"`)} </Link>
                    {t("და")}
                    <Link to={`/${i18n.language}/rules`} target="_blank" style={{color:COLORS.primary}}> {t(`"კონფიდენციალურობის პოლიტიკას"`)} </Link>

                </div>
            </Form.Group>

        </Form>
      </Modal.Body>
    </Modal>
  );
};

SignUpModal.propTypes = {
  show: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func,
};
SignUpModal.defaultProps = {
  show: true,
  centered: true,
  initialTab: 'password',
};
