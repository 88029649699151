import { Routes, Route, Link } from "react-router-dom";
import MainScreen from "./main";
import FavouritesScreen from "./favourites";
import NotificationsScreen from "./notifications";
import OrdersScreen from "./orders";
import ProfileScreen from "./profile";
import ContactScreen from "./contact";
import ProductsScreen from "./products";
import ProductScreen from "./product";
import SearchScreen from "./search";
import RulesScreen from "./rules";

const MainRouter = ()=>{
	return (
			<Routes>
				<Route path="/" element={<MainScreen/>}/>
				<Route path="/:lang" element={<MainScreen/>}/>
				<Route path="/favourites" element={<FavouritesScreen/>}/>
				<Route path="/:lang/favourites" element={<FavouritesScreen/>}/>
				<Route path="/notifications" element={<NotificationsScreen/>}/>
				<Route path="/:lang/notifications" element={<NotificationsScreen/>}/>
				<Route path="/products/:menu_id" element={<ProductsScreen/>}/>
				<Route path="/:lang/products/:menu_id" element={<ProductsScreen/>}/>
				<Route path="/product/:id" element={<ProductScreen/>}/>
				<Route path="/:lang/product/:id" element={<ProductScreen/>}/>
				<Route path="/orders" element={<OrdersScreen/>}/>
				<Route path="/:lang/orders" element={<OrdersScreen/>}/>
				<Route path="/profile" element={<ProfileScreen/>}/>
				<Route path="/:lang/profile" element={<ProfileScreen/>}/>
				<Route path="/contact" element={<ContactScreen/>}/>
				<Route path="/:lang/contact" element={<ContactScreen/>}/>
				<Route path="/search" element={<SearchScreen/>}/>
				<Route path="/:lang/search" element={<SearchScreen/>}/>
				<Route path="/:lang/rules" element={<RulesScreen/>}/>
				<Route path="/rules" element={<RulesScreen/>}/>
				<Route path="/:lang/terms" element={<RulesScreen/>}/>
				<Route path="/terms" element={<RulesScreen/>}/>
			</Routes>

	)
}
export default MainRouter