import React from 'react';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import thunkMiddleware from 'redux-thunk';
import tabReducer from "./tab/tabReducer";
import productReducer from "./reducers/products/productReducer";
import userReducer from "./reducers/user/userReducer";
import notificationReducer from "./reducers/notifications/notificationReducer";
import favouriteReducer from "./reducers/favourites/favouriteReducer";
import OrderReducer from "./reducers/orders/orderReducer";
import selectedProductReducer from "./reducers/products/selectedProductReducer";

const middleware = applyMiddleware(thunkMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Store = createStore(
    combineReducers({
        Tab:tabReducer,
        User:userReducer,
        Products:productReducer,
        SelectedProducts:selectedProductReducer,
        Notifications:notificationReducer,
        Favourites:favouriteReducer,
        Orders:OrderReducer,
    }),
    composeEnhancers(middleware)
)
export default Store;

