import {memo, useEffect, useState} from "react";
import {useFavourite} from "../store/hooks/useFavourite";
import {useProduct} from "../store/hooks/useProduct";
import icons from "../constants/icons";
import {COLORS} from "../constants";
import {CardSlider, ProductList} from "../components";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Config from "../store/config/Config";
import './product.scss';
import {useOrder} from "../store/hooks/useOrder";
import _ from "lodash";
import UseCartModal from "../store/hooks/useCartModal";
import {UseSignIn, UseUser} from "../store/hooks";
import {useTranslation} from "../store/i18n/i18n";


const ProductScreen = () =>{
	const {t} = useTranslation()
	const cartModal = UseCartModal();
	const {User,CheckSession} = UseUser();
	const {id} = useParams()
	const productsHook = useProduct();
	const [loader,setLoader]=useState(false)
	const [product,setProduct]=useState(null)
	const [count,setCount]=useState(1);
	const  {addToCart,cart} = useOrder()
	const [qty,setQty] = useState(1)
	const signInModal = UseSignIn();
	const favHook = useFavourite();

	useEffect(()=>{
		getProduct()
	},[id])

	const getProduct=()=>{
		productsHook.getProductById(id,setLoader)
			.then(response=>{
				setProduct(response.status?response.data:null)
			})
	}

	const changeCount=(method)=>{
		if(method === 'minus'){
			qty > 1? setQty(count-1):setQty(1)
		}else{
			setQty(qty+1)
		}
	}

	const prize =(money)=> {
		return Number.parseFloat(money).toFixed(2);
	}

	return product && <Container className={"main-container"}>
		<div className="product_wrap">
			<div className="prod_box">
				<div style={{flex:1, padding:"10px", border: "1px solid #e4e4e4", borderRadius:"30px",aspectRatio:2}}>
					<img src={Config.IMAGE_URI+product.image} alt="" style={{borderRadius:"20px",width:"100%"}}/>
				</div>
			</div>
			<div className="prod_box">
				<div style={{display:'flex', flexDirection:'column'}}>
					<h1 style={{fontWeight:'bold'}}>{product?.product_name}</h1>
					<p style={{color:"gray"}}>{product?.product_description}</p>

					<div className="product_prize">
						<h4>{prize(product?.product_price) } ₾</h4>
					</div>
					<hr/>
					<div className="product-method-wrapper">
						<div className="product_count">
							<Button variant="light" onClick={()=> changeCount('minus')}>-</Button>
							<span><strong>{qty}</strong></span>
							<Button variant="light" onClick={()=> changeCount('plus')}>+</Button>
						</div>
						<div className="product-card-button">
							<Button variant="warning" onClick={()=>{

								if(User.isLogged){
									product["qty"]=qty;
									product["sumPrice"]=(product?.product_price * qty).toFixed(2)
									if(cart.map(v=>v.product_id).indexOf(product.product_id)===-1){
										if(addToCart(product)){
											cartModal.open({
												onSuccess:()=>CheckSession()
											})
										}
									}else{
										cartModal.open({
											onSuccess:()=>CheckSession()
										})
									}



								}else{
									signInModal.open({
										onSuccess:()=>CheckSession()
									})
								}


							}}>
								<img src={icons.cart} width="16"  alt=""/>
								<span style={{fontSize:'12px', fontWeight:'bold'}} >{t("დამატება")}</span>
							</Button>
							&nbsp;
						</div>
						<div className="heart-btn">
							<Button variant={product?.isFavourite? 'light':'secondary'}  onClick={()=>{
								if(product?.isFavourite){
									favHook.removeFavourite(product.product_id,0).then(()=>getProduct())
								}else{
									favHook.addFavourite(product.product_id).then(()=>getProduct())
								}}
							}>
								<img src={product?.isFavourite? icons.isFavourite:icons.favourite} width="20" />
							</Button>
						</div>

					</div>
					<hr/>
					<div className="product_soc">
						<p>
							<a href="https://www.facebook.com/beertimegeorgia" target="_blank"><span><i className="fa-brands fa-facebook"/></span></a>
							<a href="https://www.messenger.com/t/474278039604318" target="_blank"><span><i className="fa-brands fa-facebook-messenger"/></span></a>
							<a href="https://www.instagram.com/beertimegeorgia/" target="_blank"><span><i className="fa-brands fa-instagram"/></span></a>
						</p>
					</div>
					{/*<hr/>
					<ul className="product_info">
						<li>Free global shipping on all orders</li>
						<li>30 days easy returns if you change your mind</li>
						<li>Order before noon for same day dispatch</li>
					</ul>
					<hr/>
					<br/>*/}
					{/*<div className="product_payment">
						<span style={{fontWeight:'bold'}}>Guaranteed Safe Checkout</span>
						<span style={{marginLeft:'20px'}}><img src="https://demo2wpopal.b-cdn.net/poco/wp-content/uploads/2020/08/footer_img1.png" alt=""/></span>
					</div>*/}
					<br/>
					<br/>
					<br/>
				</div>
			</div>
		</div>
	</Container>
}
export default memo(ProductScreen)