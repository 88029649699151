const UseEvent = () => {
    return {
        subscribe: (name, cb) => {
            (window.events[name] || (window.events[name] = [])).push(cb);
            return {
                unsubscribe: () => {
                    window.events[name] && window.events[name].splice(window.events[name].indexOf(cb), 1);
                }
            };
        },
        emit: (name, data) => {
            (window.events[name] || []).forEach(fn => fn(data));
        }
    };
}
export default UseEvent
