import React from 'react';
import {memo, useEffect, useState} from "react";
import {useProduct} from "../store/hooks/useProduct";
import icons from "../constants/icons";
import {Container} from "react-bootstrap";
import '../assets/prodacts.scss';
import {NewCard} from "../components/card/newCard";
import _ from "lodash";
import pageTitleBg from "../assets/img/page-title-bg.jpg";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useFavourite} from "../store/hooks/useFavourite";



const ProductsScreen = () =>{
	const favHook = useFavourite();

	const {t} = useTranslation()
	const [loader,setLoader]=useState(false)
	const {Products,getProducts,setSelectedProducts} = useProduct()
	let navigate = useNavigate();
	let params = useParams();
	const [selected,setSelected]=useState(null)
	const [cat,setCat]=useState(params?.menu_id);
	useEffect(()=>{
		getProducts()
		setCat(params?.menu_id)
	},[params])

	useEffect(()=>{
		if(cat && Products){
			let find = _.find(Products,v=>v.id===parseInt(cat));
			setSelected(find)
		}

	},[cat,Products])



	return selected ? (
		<Container className={"main-container"}>
			<div className="page-title" style={{background:`url(${pageTitleBg})`}}>
				<h1>{selected?.title}</h1>
			</div>
			<Container>
				<div className="products_container">
					<div className="prodacts">
						<div className="prodacts-container">
							{
								selected?.products.length > 0 ?
									<>
										<br/>
										<div className="title">
											<h3>{selected?.title}</h3>
										</div>

										{/*<ProductList products={productsHook?.SelectedProducts?.products}/>*/}
										<div className="prodacts-wrap">
											{
												_.map(selected?.products,(v,k)=>{
													return (
														<NewCard key={k} v={v} onClick={()=>{
															if(v.isFavourite){
																favHook.removeFavourite(v.product_id,k).then(()=>getProducts())
															}else{
																favHook.addFavourite(v.product_id).then(()=>getProducts())
															}
														}}/>
													)
												})
											}

										</div>


									</>:
									<div style={{flex:1,justifyContent:'center',alignItems:'center'}}>
										<img src={loader?icons.spinner:icons.nothingFound} style={{height:50,width:50}}/>
										<span>{t("კერძები არ მოიძებნა")}</span>
									</div>
							}
						</div>
					</div>
					<div className="products_right">
						<br/>
						<div className="product_categories">
							<h3>{t("კატეგორიები")}</h3>
							<ul className="product-categories">
								{ _.size(Products)> 0 && Products.map(v=> {
									// active კლასი ლის აქტიურ ტაბზე
									return <li className={`cat-item cat-item-33 ${parseInt(cat) === v.id? 'active':''}`}>
										<a onClick={()=>{
											setSelectedProducts(v)
											navigate(`/products/${v.id}`)
										}}>
{/*
											<i data-id={v.id} style={{background:`url(${Config.IMAGE_URI+v?.icon})`}}/>
*/}
											<span>{v.title}</span>
										</a>
										{/*<span className="count">({_.size(v.products)})</span>*/}
									</li>
								})
								}

							</ul>
						</div>
					</div>
				</div>
			</Container>
		</Container>
	):(
		<Container style={{
			minHeight:`calc(100vh - 300px)`
		}}>
			<div style={{flex:1,justifyContent:'center',alignItems:'center',marginTop:'30px'}}>
				<img src={loader?icons.spinner:icons.nothingFound} style={{height:50,width:50}}/>
				<span>{t("კერძები არ მოიძებნა")}</span>
			</div>
		</Container>

	)
}
export default memo(ProductsScreen)