import { UseEvent, UseUser } from './index';

export default function UseSignIn(){
  const event  = UseEvent();
  const { keepAlive } = UseUser();
  const open=(params={})=>{
    event.emit("event",{
      type:"SignUp",
      ...params,
      onSuccess:(e)=>{
        keepAlive().then(()=>{
          params.onSuccess(e)
        });
      }
    })
  }

  return  {open}
}
