import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import classes from './OtpModal.module.scss';
import classNames from 'classnames';
import { ClosePrimaryIcon, DividerIcon } from '../../../assets/icons/modal';
import {useTranslation} from "../../../store/i18n/i18n"
export const OTPModal= ({ description, onSuccess,onResend, onCancel, variant ,...props }) => {
  const [smsCode, setSmsCode] = useState("");
  const [error,setError]= useState("")
  const [timer,setTimer] = useState(60)
  const {t} = useTranslation()

  useEffect(()=>{
    let interval = setInterval(()=>{
      setTimer(prevState =>(prevState>0)?prevState - 1:prevState)
    },1000)
    if(document.getElementsByClassName("modal")[1]){
      document.getElementsByClassName("modal")[1].style.zIndex=1000
    }
    return  () =>{
      if(document.getElementsByClassName("modal")[0]){
        document.getElementsByClassName("modal")[0].style.zIndex=9999
      }

      clearInterval(interval)
    }
  },[])
  return (
    <Modal
      onHide={onCancel}
      container={document.getElementById('events')}
      contentClassName={classNames(
        classes.modal,'modal-restyle',
        {
          [classes.dark]:true,
        }
      )}
      {...props}
    >
      <Modal.Header
        className={classes.header}
        style={{ '--close': `url(${ClosePrimaryIcon})` }}
        closeButton
        closeVariant={variant==="dark"?"white":null}
      >
        {t("ერთჯერადი SMS კოდის შემოწმება")}
      </Modal.Header>
      <Modal.Body style={{paddingTop:'0'}} className={classes.body}>
        <Form style={{marginTop:'0'}} className={classes.form} onSubmit={e=>{
          e.preventDefault();
        }}>
          <Form.Group style={{marginBottom:'10px'}} className={`form-box ${classes.formGroup}`}>
            <Form.Label style={{fontSize:'18px',lineHeight:'20px',fontFamily:'DejaVu Sans, sans-serif'}}>{description}</Form.Label>
            <div style={{position:'relative'}}>
              <Form.Control type={'number'} isInvalid={error} value={smsCode} onChange={(e)=>{
                setError("")
                setSmsCode(e.target.value)
              }} />
              {
                (
                  <div style={{position:'absolute', right:2,top:"50%", transform:'translateY(-50%)'}}>
                   {/* <CountDown
                      onComplete={()=>setResend(false)}
                      Duration={timer}
                      Colors={['#ffca2c']}
                      ColorsTime={[0]}
                      BackgroundColor={"#373940"} />*/}
                  </div>
                )
              }
            </div>
          </Form.Group>
          <Form.Group style={{marginBottom:'0'}} className={classes.formGroup}>
            <div style={{display:'flex',flexDirection:'row', alignItems:'space-between'}}>
              <div>
                {
                  <Form.Label style={{fontSize:'18px',fontFamily:'DejaVu Sans, sans-serif',textDecoration:"underline",cursor:'pointer'}} onClick={()=>{
                    if(timer ===0){
                      onResend((time,error)=>{
                        setError(error || "")
                        setTimer(time);
                        setSmsCode("")

                      })
                    }

                  }  }>{t("თავიდან გაგზავნა")} ({timer})</Form.Label>
                }
              </div>

              <div style={{flex:1,justifyContent:'flex-end',display:'flex',fontSize:"1rem",color:'red'}}>{error}</div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        className={classes.footer}
        style={{ '--divider': `url(${DividerIcon})` }}
      >
        <Stack
          direction={'horizontal'}
          gap={3}
          className={`btn_box ${classes.buttonsContainer}`}
          style={{width:'100%'}}
        >
          <Button size={'sm'} variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
          <Button size={'sm'} variant={'success'} type="submit" onClick={()=>{
              onSuccess(smsCode,(error)=>{
                setSmsCode("")
                setError(error)
              },onCancel)
            }}>{t("დასტური")}</Button>

        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

OTPModal.propTypes = {
  show: PropTypes.bool,
  centered: PropTypes.bool,
  description: PropTypes.string,
  onSuccess: PropTypes.func,
  onResend: PropTypes.func,
  onCancel: PropTypes.func,
  variant:PropTypes.string
};
OTPModal.defaultProps = {
  show: true,
  centered: true,
  description: 'გთხოვთ, მიუთითეთ თქვენს ნომერზე გამოგზავნილი SMS კოდი',
  variant:"dark",
  onResend:()=>console.log("resend")
};
