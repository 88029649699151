import {GET_SELECTED_PRODUCTS} from "./productTypes";

const initialState=null;

const selectedProductReducer = (state=initialState, action)=>{
    switch (action.type){
        case GET_SELECTED_PRODUCTS:
            return {...action.payload}
        default: return {...state}
    }

}

export default selectedProductReducer;
