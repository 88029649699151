import {memo} from "react";
import "./contact.scss"
import {Button, Container, Form, Stack} from "react-bootstrap";
import pageTitleBg from '../assets/img/page-title-bg.jpg';
import sushiTime from '../assets/img/sushi-time.jpg';
import {useTranslation} from "../store/i18n/i18n";

const ContactScreen = () =>{

	const {t} = useTranslation()
	return (
		<Container className={"main-container"}>
			<div className="page-title" style={{background:`url(${pageTitleBg})`}}>
				<h1>{t("საკონტაქტო ინფორმაცია")}</h1>
			</div>
			<Container>
				<div className={"contact-container"}>
					<div>
						<h3><b>{t("დაგვირეკეთ ან ეწვიეთ ადგილს")}</b></h3>
						<p style={{color:'gray'}}>{t("დაჯავშნა შეგიძლია როგორც საიტიდან ასევე დარეკვით")}</p>
						<Stack direction="horizontal" gap={3}>
							<div className={"contact-icon-container col"} >
								<div className={"contact-icon"}>
									<i className="fa-solid fa-mobile-screen"/>
								</div>
								<div>
									<h5><strong>{t("ტელეფონი")}:</strong></h5>
									<p style={{fontFamily:'unset'}}>+995 511 202 020</p>
								</div>
							</div>
							<div className={"contact-icon-container col"} >
								<div className={"contact-icon"}>
									<i className="fa-solid fa-location-dot"/>
								</div>
								<div>
									<h5><strong>{t("მისამართი")}:</strong></h5>
									<p>
										{t("ალ. ყაზბეგის 12ბ")}
										<br/>
										{t("თბილისი, საქართველო")}
									</p>

								</div>
							</div>
							<div className={"contact-icon-container col"} >
								<div className={"contact-icon"}>
									<i className="fa-solid fa-envelope-circle-check"/>
								</div>
								<div>
									<h5><strong>{t("ელფოსტა")}:</strong></h5>
									<p>
										{t("info@beertime.ge")}
									</p>
								</div>
							</div>
						</Stack>
					</div>
					<hr/>
					<Stack className="contact-form" direction="horizontal" gap={3}>
						<div className={"map contact-icon-container col"} style={{background:`url(${sushiTime})`}}>
							<iframe
								title="map"
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2977.7601604046304!2d44.76026075181214!3d41.72569398281348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404472dffae8ad4d%3A0x414bfa804f1046d!2sBeer%20Time!5e0!3m2!1sen!2sge!4v1654551973500!5m2!1sen!2sge"
								width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"/>
						</div>
						<div className={"form-box contact-icon-container col"} >
							<h3> <strong>{t("მოგვწერეთ შეტყობინება")}</strong> </h3>
							<Form style={{
								width:"100%"
							}}>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label>{t("თქვენი სახელი")}</Form.Label>
									<Form.Control type="text" placeholder="სახელი" />
								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label>{t("ელფოსტა")}</Form.Label>
									<Form.Control type="email" placeholder="name@example.com" />
								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Form.Label>{t("შეტყობინება")}</Form.Label>
									<Form.Control as="textarea" rows={3} />
								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
									<Button variant="outline-primary"  style={{
										backgroundColor:"#ffc222",
										color:"black",
										borderColor:"#ffc222"
									}}> {t("გაგზავნა")}</Button>
								</Form.Group>
							</Form>
						</div>
					</Stack>
				</div>
			</Container>
		</Container>

	)
}
export default memo(ContactScreen)