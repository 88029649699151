import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';

import useUser from "../../../store/hooks/useUser";
import {utils} from "../../../utils";
import {API} from "../../../store";
import Toast from "../../../store/helpers/toast"
import {UseRecovery, UseSignUp} from "../../../store/hooks";
import {useTranslation} from "react-i18next";
import './signInModal.scss';
import {COLORS} from "../../../constants";

export const SignInModal = ({ onCancel,onSuccess,  ...props }) => {
    const {t} = useTranslation()
    const recovery = UseRecovery()
    const signUpModal = UseSignUp();
    const {CheckSession,UpdateStatus} = useUser()
    const [phone,setPhone]=useState("")
    const [phoneError,setPhoneError]=useState("")
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPass,setShowPass]=useState(false)
    const [passType, setPassType] = useState({
        pass1:'password',
        pass2:'password'
    });
    const isEnabledSignIn=()=>{
        return phone !== "" && password !=="" && phoneError ==="" && passwordError===""
    }
    const togglePassType=(pass)=>{
        if(pass === 'pass1'){
            setPassType(passType.pass1 === 'text'?{...passType,pass1:'password'}:{...passType,pass1:'text'})
        }else{
            setPassType(passType.pass2 === 'text'?{...passType,pass2:'password'}:{...passType,pass2:'text'})
        }

    }
  return (
    <Modal contentClassName="sign-in-modal-restyle" onHide={onCancel} {...props}  draggable >
      <Modal.Header closeButton>
        <Modal.Title>{t("ავტორიზაცია")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e)=>{
          e.preventDefault();
            if(isEnabledSignIn()) {
                API.Guest.SignIn({
                    data: {
                        username: phone,
                        password: password
                    }
                }).then(response => {
                    if (response.status) {
                        CheckSession().then(response => {
                            if (response?.status) {

                                UpdateStatus({
                                    loaded: true,
                                    data: response?.data,
                                    isLogged: response?.status
                                })
                                onSuccess();
                                onCancel()

                            }
                        })

                    } else {
                        Toast.show({
                            type: "error",
                            text1: response.data,
                            visibilityTime: 3000,
                            autoHide: true
                        })
                    }
                }).catch(ex => {
                    /* console.log(ex)*/
                })
                //navigation.navigate("Home")
            }else {
                Toast.show({
                    type: "error",
                    text1:t("შეავსეთ ყველა ველი") ,
                    visibilityTime: 3000,
                    autoHide: true
                })
            }


        }} >
          <Form.Group className="form-box">
              <div className="input-label">
                    <Form.Control value={phone} isInvalid={phoneError!==""} type="number" onChange={e=>{
                        let value= e.target.value;
                        utils.validatePhone(value,setPhoneError)
                        setPhone(value)
                    }}/>
                    <Form.Label>{t("მობილური")}</Form.Label>

              </div>
              <Form.Control.Feedback type="invalid">{t(phoneError)}</Form.Control.Feedback>
             {/* {
                  phoneError !== '' && phoneError && <p style={{fontSize:'12px',padding:'10px 7px 0',color:'#dc3545'}}>შეიყვანეთ 9 ნიშნა მობილურის ნომერი </p>
              }
*/}
          </Form.Group>
          <Form.Group className="form-box">

            <div className="input-label">
              <Form.Control value={password} type={passType.pass1}  isInvalid={passwordError !==""} onChange={e=>{
                  let value= e.target.value;
                  utils.validatePassword(value,setPasswordError)
                  setPassword(value)
              }}/>
                <Form.Label>{t("პაროლი")}</Form.Label>
                <div className={`toggle-password ${passType.pass1==='text'?'active':'hide'}`} onClick={()=>{togglePassType('pass1')}}/>
              {/*<Icon
                style={{
                  position:'absolute',
                  right:"25px",
                 }}
                Icon={!showPassword?EyeSlashIcon:EyeIcon}
                height="20px"
                native
                width="20px"
                onClick={()=>setShowPassword(!showPassword)}
              />*/}
            </div>
            <div style={{color:'red',fontSize:'0.875em'}}>
              {t(passwordError)}
            </div>
            <span className="rec-pass" onClick={()=>{
                onCancel()
                recovery.recovery({
                    onSuccess:()=>console.log("success")
                })
            }}>{t("პაროლის აღდგენა")}</span>
          </Form.Group>
          <Stack
              className="btn_box"
            direction={'horizontal'}
            style={{ justifyContent: 'flex-end' }}
            gap={4}
          >
              <Button variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
              <Button variant={'success'} type={"submit"}>{t("ავტორიზაცია")}</Button>
          </Stack>
            <br/>

        </Form>
          <Stack
              className="btn_box"
              direction={'horizontal'}
              style={{ justifyContent: 'flex-end' }}
              gap={4}
          >
              <Button variant={'link'} style={{textDecoration:'none',color:COLORS.primary}}
                      onClick={()=> signUpModal.open({
                          onSuccess:()=>CheckSession()
                      })}
              >{t("რეგისტრაცია")}</Button>
          </Stack>
      </Modal.Body>
    </Modal>
  );
};

SignInModal.propTypes = {
  show: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func,
};
SignInModal.defaultProps = {
  show: true,
  centered: true,
  initialTab: 'password',
};
