import React, {useState} from 'react';
import './delishes.scss';


import _ from "lodash";
import Config from "../../store/config/Config";
import {COLORS, icons} from "../../constants";
import {Link, useNavigate, useParams} from "react-router-dom";
import {i18n, useTranslation} from "../../store/i18n/i18n";
import {Heart} from "../index";
import {useFavourite} from "../../store/hooks/useFavourite";
import CartIcon from "../CartIcon/CartIcon";
import AddToCart from "../CartIcon/CartIcon";
import {Navigation, Pagination} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import {NewCard} from "../card/newCard"

const Delishes = ({products,onRefresh}) =>{
    const navigate = useNavigate()
    const [index,setIndex] = useState(0)
    const favHook = useFavourite();
    const {lang='ka'}=useParams()
    const {t} = useTranslation()
    const prize =(money)=> {
        return Number.parseFloat(money).toFixed(2);
    }
    const config = {
        //2:{
        //	slidesPerView:3
        //}
    }
    return (
        <div className='delishes'>
{/*
            <h2>Popular Dishes</h2>
*/}

            <div className="delish-filter mw">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={"auto"}
                    centeredSlides={false}
                    //navigation={true}
                    //pagination={{
                    //    clickable: true,
                    //}}
                    //navigation={true}

                    modules={[ Pagination,Navigation]}
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        440: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 5,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 7,
                            spaceBetween: 10,
                        },
                    }}

                >
                    {
                        _.map(products, (v,k)=> {
                            return (
                                <SwiperSlide key={k}>
                                    <div  className={`filter-item ${k===index?'active':''}`} key={k} onClick={()=>setIndex(k)}>{v.title}</div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
            </div>

            <div className="mw">
                <div className="delish-container">
                    <ul>
                        {
                            _.size(products)>0 && _.map(products[index]["products"],(v,k)=>{
                                return (
                                    <li key={k} onClick={()=>navigate(`/${i18n.language}/product/${v.id}`)}>
                                        <div className="product-block">
                                            {/*<span className="onsale">Sale!</span>*/}
                                            <div >
                                                 <span className="heart"><Heart {...v} onClick={()=>{
                                                     if(v.isFavourite){
                                                         favHook.removeFavourite(v.product_id,k).then(()=>onRefresh())
                                                     }else{
                                                         favHook.addFavourite(v.product_id).then(()=>onRefresh())
                                                     }
                                                 }}/></span>

                                                <div className="product-transition">
                                                    {/*<button>Add to wishlist</button>*/}
                                                    <div className="product-image" style={{background:`#ccc url(${Config.IMAGE_URI+v?.image})`}}/>
                                                </div>
                                            </div>
                                            <div className="product-caption" >
                                                <h3><a href="#">{v.product_name}</a></h3>
                                                {/*<div className="short-description"> {v?.product_description}</div>*/}
                                                <span className="price">
                                                    <span className="last-price">{prize(v.product_price)} ₾</span>
                                                    <AddToCart item={v}/>
                                                </span>
                                            </div>

                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
            </div>

            <div className="all-delish">
                <Link to={`/${lang}/products/${products[0]["id"]}`}><span>{t("ყველა კერძი")}</span></Link>
            </div>

        </div>
    )
}

export default Delishes;
