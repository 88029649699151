import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from "./store/store";
import {Provider} from "react-redux";
import Events from "./components/events/events";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter} from "react-router-dom";
//const root = ReactDOM.createRoot(document.getElementById('root'));
import {i18n} from "./store/i18n/i18n";
ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Provider store={Store}>
              <App/>
              <Events/>
              <ToastContainer />
          </Provider>
      </BrowserRouter>

  </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
