import {useDispatch, useSelector} from "react-redux";
import {
	DeleteNotification,
	GetNotifications,
	ReadNotification,
	UnreadNotifications
} from "../reducers/notifications/notificationActions";

export const useNotifications = ()=> {
	const dispatch = useDispatch();
	const {Notifications,unreadNotificationCount} = useSelector(state => state.Notifications)

	const getNotifications = (loader) => {
	  	dispatch(GetNotifications({loader:loader}))
	}
	const readNotification = (id,index)=>{

		dispatch(ReadNotification(id,index))
		dispatch(UnreadNotifications())
	}
	const deleteNotification =(id, index) => {
		return  dispatch(DeleteNotification(id, index))
	}

	const unreadNotifications = () => {
		return  dispatch(UnreadNotifications())
	}
	return {Notifications,getNotifications,readNotification,deleteNotification,unreadNotifications,unreadNotificationCount}
}