import React from "react";

import './cart.scss';
import {UseReservation, UseSignIn, UseUser} from "../../store/hooks";
import _ from "lodash";
import {useOrder} from "../../store/hooks/useOrder";
import {i18n, useTranslation} from "../../store/i18n/i18n";
import Config from "../../store/config/Config"


const Cart = ({data,onCancel}) =>{


	const {t} =useTranslation()
	const {updateCart} = useOrder()
	const reservationModal = UseReservation();
	const {User,CheckSession} = UseUser();
	const signInModal = UseSignIn();

	const renderSum = ()=> {
		return data?.map(v=>v.sumPrice).reduce((a,b=0)=>{
			return a *1 + b * 1
		}, 0)|| 0
	}

	const prize =(money)=> {
		return Number.parseFloat(money).toFixed(2);
	}

	return (
		<ul className="cart_wrapper" style={{maxHeight: 'calc(100vh - 250px)'}}>
			{/*<li className="cart-header">კალათა</li>*/}
			{
				_.map(data,(v,k)=>{

					return (
						<li className="cont" key={k} >

							<div className='img-part' style={{backgroundImage:`url(${Config.IMAGE_URI+v.image})`}}>
								<div className='img-div' />
							</div>
							<div className='info-part'>
								<span>{v?.[`product_name_${i18n.language}`] ||v?.product_name } </span>
								<span>{prize(v.product_price)} ₾</span>
							</div>
							<div className='act-part'>
								<p className='remove-btn' onClick={()=>{
									data.splice(k,1);
									updateCart()
								}}>{
									t("წაშლა")
								}</p>
								<p className='act-btns'>
									<span className='btn-remove' onClick={()=>{
										if(v.qty>1){
											v.qty-=1;
											v.sumPrice= (v.product_price * v.qty).toFixed(2);
											updateCart()
										}
									}}>-</span>
									<span>{v.qty}</span>
									<span className='btn-add' onClick={()=>{
										if(v.qty<100){
											v.qty+=1;
											v.sumPrice= (v.product_price * v.qty).toFixed(2);
											updateCart()
										}
									}}>+</span>
								</p>
							</div>
						</li>
					)
				})
			}




			<li className="cart-footer">
				<div className="cf_money">
					<p>{t("სულ თანხა")}</p><span>{renderSum()} {t("ლარი")}</span>
				</div>
				<div className="cf_money">
					<p>{t("მომსახურების საკომისიო")}</p><span>15%</span>
				</div>
				<div className="cf_calc">
					<p>{t("ჯამი")}</p><span>{(renderSum() * 1.15).toFixed(2)} {t("ლარი")}</span>
				</div>
				<div className="cf_button" onClick={()=> {
					if(!User.isLogged){
						signInModal.open({
							onSuccess:()=>CheckSession()
						})
					}else{
						reservationModal.open({
							onSuccess:()=>CheckSession()
						})
					}
					onCancel();
				}}>
					{t("სივრცის დაჯავშნა")}
				</div>

				<div className="cf_clear" style={{background:'transparent',cursor:'pointer'}} onClick={()=> {
					updateCart([])
				}}>
					{t("კალათის გასუფთავება")}
				</div>
			</li>
		</ul>
	)
}
export default Cart;
