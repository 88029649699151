import {GET_PRODUCTS} from "./productTypes";
import {API} from "../../index";

export  const GetAllProducts =({loader})=>(dispatch)=>{

    API.Product.GetAllProducts({loader:loader}).then(response=>{
        return dispatch({
            type:GET_PRODUCTS,
            payload:response.status?response.data:[]
        })
    })

}
