import Client from "../Client";
import Config from "../config/Config";
const SignOut  = () =>{
     return Client.get({url: Config.USER.SIGN_OUT})
}

const update= ({data,loader}) =>{
    return Client.post({url: Config.USER.UPDATE,data:data,loader:loader})
}

const updatePassword= ({data,loader}) =>{
    return Client.post({url: Config.USER.UPDATE_PASSWORD,data:data,loader:loader})
}
export function myCard() {
    return Client.get({url: Config.USER.MY_CARD})
}

const removeFavourite= (id) =>{
    return Client.get({url: Config.USER.REMOVE_FAVOURITE.replace("{id}",id)})
}

const addFavourite= (id) =>{
    return Client.get({url: Config.USER.ADD_FAVOURITE.replace("{id}",id)})
}

const notifications = () =>{
    return Client.get({url: Config.USER.NOTIFICATIONS})
}
const readNotification = (id) =>{
    return Client.get({url: Config.USER.READ_NOTIFICATION.replace("{id}",id)})
}
const deleteNotification = (id) =>{
    return Client.get({url: Config.USER.DELETE_NOTIFICATION.replace("{id}",id)})
}

const getUnreadNotifications= () =>{
    return Client.get({url: Config.USER.GET_UNREAD_NOTIFICATIONS})

}
const UploadProfilePicture =(base64,loader) =>{
    return Client.post({
            url: Config.USER.UPLOAD_PROFILE_PICTURE,
            data:{
                picture:base64
            },
            loader:loader
    })
}
export  {
    SignOut,
    removeFavourite,
    addFavourite,
    update,
    updatePassword,
    notifications,
    readNotification,
    deleteNotification,
    UploadProfilePicture,
    getUnreadNotifications
}
