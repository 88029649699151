import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "./rules.scss"
export default function RulesScreen(){
	const {lang='ka'}=useParams()
	return (
		<Container className={"main-container"}>
			<iframe src={`/assets/termsAndConditions/terms_${lang}.html`} className={"rulesFrame"} />
		</Container>
	)
}