import {CardSlider, Delishes, Footer, HorizontalMenu, Slider} from "../components";
import _ from "lodash";
import {useProduct} from "../store/hooks/useProduct";
import {memo, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSelectedTab} from "../store/tab/tabActions";
import {i18n, useTranslation} from "../store/i18n/i18n";


const MainScreen  = () =>{
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const {Products,getProducts,setSelectedProducts} = useProduct()

	useEffect(()=>{
		dispatch(setSelectedTab("main"))
		getProducts()
	},[])
	return (
		<>
			<Slider/>
			{/*<div className="menu-btn"><a href="/ff">მენიუ</a></div>*/}

			{ _.size(Products)> 0 && <HorizontalMenu products={Products.filter(v=>parseInt(v.type)===4)} />}

			{/*<HorizontalMenu/>*/}

			{
				_.size(Products)> 0  && _.map(Products.filter(v=>[1,2].indexOf(parseInt(v.type))>-1), (cat,key)=><CardSlider onRefresh={()=>getProducts()} onSelectedCategory={()=>{
					setSelectedProducts(cat)
					navigate(`/${i18n.language}/products/${cat.id}`)
				}} type={cat?.type} key={key} title={cat.title} id={cat.id} category={cat} products={ cat["products"]}/>)
			}

			{/*{
				_.size(Products)> 0  && _.map(Products.filter(v=>[1,2].indexOf(parseInt(v.type))>-1), (cat,key)=><ProductSlider onSelectedCategory={()=>{
					setSelectedProducts(cat)
					navigate(`/products/${cat.title}`)
				}} key={key} title={cat.title} category={cat} products={ cat["products"]}/>)
			}*/}

			{/* <OfferCards/> */}

			{ _.size(Products)> 0 && <Delishes products={Products.filter(v=>parseInt(v.type)===3)}  onRefresh={()=>getProducts()}/>}

		</>
	)
}
export default memo(MainScreen);