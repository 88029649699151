import {useDispatch, useSelector} from "react-redux";
import {API} from "../index";
import {Ping} from "../reducers/user/userActions";
import {useStorage} from "./useStorage";
import Toast from "../helpers/toast";
import {useOrder} from "./useOrder"
export default function UseUser(){
    const dispatch = useDispatch();
    const store = useStorage();
    const order= useOrder()
    const User = useSelector(state=>state.User)
    const CheckSession =  () => {
        return  API.Guest.Ping();
    }
    const keepAlive =  () => {
        return  API.Guest.Ping();
    }
    const logout = (navigation)=>{

        API.User.SignOut().then(response=>{
            order.updateCart([])
            if(response.status){
                dispatch(Ping({
                    isLogged:false,
                    data:{}
                }));
            }else{
                Toast.show({
                    type:"error",
                    text1:"სისტემიდან გასვლისას დაფიქსირდა შეცდომა",
                    visibilityTime:3000,
                    autoHide:true
                })
            }
        })

    }
    const signIn = (data) => {
        return API.Guest.SignIn(data);
    }
    const UpdateStatus =({loaded=true,data={
        "firstName": "",
        "idNumber": "",
        "lastName": "",
        "phone": "",
        "picture": "",
        "username": "",
    },isLogged=false})=>{
        dispatch(Ping({
            isLogged:isLogged,
            data:data,
            isLoaded:loaded
        }));
    }
    const UploadProfilePicture=(base64,loader=null)=>{
         API.User.UploadProfilePicture(base64,loader)
             .then(response=>{
                 if(response.status){
                     dispatch(Ping({
                         isLogged:true,
                         data:{...User.data,picture:response.data}
                     }))
                 }else{
                     Toast.show({
                         type:'error',
                         text1:response.data,
                         autoHide:true,
                         visibilityTime:2000
                     })
                 }

             })

    }
    const myCard=()=>{
        return API.User.myCard();
    }
    return {User,CheckSession,logout,signIn,UpdateStatus,UploadProfilePicture,keepAlive,myCard}
}
