import "./mobileNavigation.scss"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {i18n} from "../../store/i18n/i18n";
import {UseSignIn, UseUser} from "../../store/hooks";
import {useNotifications} from "../../store/hooks/useNotifications";

const MobileNavigation = ()=>{
	const {t} = useTranslation()
	const navigate = useNavigate();
	const {unreadNotificationCount} = useNotifications()
	const {selectedTab} = useSelector(state => state.Tab);
	const {User,myCard,logout,CheckSession,UpdateStatus} = UseUser();
	const signInModal = UseSignIn();
	return (
		<div className={"mobile-navigation-container"}>
			<div className={`navigation-item ${selectedTab ==='main'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/`)}>
				<i className="fa-solid fa-home"/>
				<span><strong>{t("ანგარიში")}</strong></span>
			</div>
		{/*	<div className={`navigation-item ${selectedTab ==='search'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/search`)}>
				<i className="fa-solid fa-magnifying-glass"/>
				<span><strong>{t("ძიება")}</strong></span>
			</div>*/}
			{
				User.isLogged ? (
					<>
					<div className={`navigation-item ${selectedTab ==='favourites'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/favourites`)}>
						<i className="fa-solid fa-heart"/>
						<span><strong>{t("ფავორიტი")}</strong></span>
					</div>
					<div className={`navigation-item ${selectedTab ==='notifications'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/notifications`)} style={{position:'relative'}}>
						<i className="fa-solid fa-bell"/>
						<span><strong>{t("შეტყობინება")}</strong></span>
						{
							unreadNotificationCount>0 && <div style={{display:'flex',justifyContent:'center',top:5,left:"50%",position:'absolute',backgroundColor:'red',color:'white',alignItems:'center',fontSize:10,borderRadius:'50%',width:14,height:14}}><i>{unreadNotificationCount}</i></div>
						}
					</div>
					<div className={`navigation-item ${selectedTab ==='orders'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/orders`)} >
						<i className="fa-solid fa-list-check"/>
						<span><strong>{t("ჯავშნები")}</strong></span>
					</div>
					</>
				):(
					<>
					<div className={`navigation-item ${selectedTab ==='contact'?'active':''}`} onClick={()=>navigate(`/${i18n.language}/contact`)}>
						<i className="fa-solid fa-phone"/>
						<span><strong>{t("კონტაქტი")}</strong></span>
					</div>
					<div className={`navigation-item ${selectedTab ==='contact'?'active':''}`} onClick={()=>signInModal.open({onSuccess:()=>CheckSession()})}>
						<i className="fa-solid fa-user"/>
						<span><strong>{t("შესვლა")}</strong></span>
					</div>
					</>
				)
			}



		</div>
	)
}
export default MobileNavigation;
