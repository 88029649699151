import React from 'react';
import './footer.scss';
import {Container} from "react-bootstrap";
import {i18n, useTranslation} from "../../store/i18n/i18n";
import {FacebookIcon, InstagramIcon, MessengerIcon,AppStore,GooglePlay} from "../../assets/icons";
import {Link, useParams} from "react-router-dom";

const Footer = () =>{
    const {t} = useTranslation()

    return (

        <>
            <section className={'footer'}>

                <Container className={'footer-container'}>
                    <div className={"footer-info"}>
                        <div>
                            <h5>{t("მისამართი")}</h5>
                            <div style={{
                                display:'flex',
                                flexDirection:'column',

                            }}>
                                <span>
                                    <i className="fa-solid fa-location-dot" style={{color:'#b0b0b0',marginRight:'10px'}}/> {t("ალ. ყაზბეგის 12ბ")}
                                </span>
                                <span>
                                    {t("თბილისი, საქართველო")}
                                </span>

                            </div>
                        </div>
                        <div>
                            <h5>{t("მაგიდის დაჯავშნა")}</h5>
                            <div style={{
                                display:'flex',
                                flexDirection:'column',

                            }}>
                                <span dangerouslySetInnerHTML={{__html:t("მაგიდის დაჯავშნა შესაძლებელია საიტიდან <br/> ან დარეკეთ ნომერზე")}}/>



                                 <span style={{color:'#ffc222',fontWeight:'bold'}}>
                                     <i className="fa-solid fa-mobile-screen-button" style={{color:'#b0b0b0',marginRight:'10px'}}/> +995 511 202 020
                                </span>

                            </div>
                        </div>
                        <div>
                            <h5>{t("სამუშაო დრო")}</h5>
                            <div style={{
                                display:'flex',
                                flexDirection:'column',

                            }}>
                                <span>
                                    {t("ორშაბათი-კვირა")}: <span style={{color:'white'}}>10:00 - 02:00</span>
                                </span>


                                <span style={{color:'#ffc222',fontWeight:'bold'}}>
                                    <div style={{flex:1,justifyContent:'flex-start',display:'flex',fontSize:'1.2rem',alignItems:'center'}}>
                                        <a href="https://www.facebook.com/beertimegeorgia" target="_blank">
                                           <div style={{
                                               backgroundImage:`url(${FacebookIcon})`,
                                               height:'30px',
                                               width:'30px',
                                               backgroundSize:'cover'
                                           }}/>
                                        </a>
                                        &nbsp;&nbsp;
                                        <a href="https://www.messenger.com/t/474278039604318" target="_blank">
                                             <div style={{
                                                 backgroundImage:`url(${MessengerIcon})`,
                                                 height:'30px',
                                                 width:'30px',
                                                 backgroundSize:'cover'
                                             }}/>
                                        </a>
                                       &nbsp;&nbsp;
                                        <a href="https://www.instagram.com/beertimegeorgia/" target="_blank">
                                            <div style={{
                                                backgroundImage:`url(${InstagramIcon})`,
                                                height:'30px',
                                                width:'30px',
                                                backgroundSize:'cover'
                                            }}/>
                                        </a>

                                    </div>
                                </span>

                            </div>
                        </div>

                    </div>
                </Container>
            </section>
            <section className="footer-area" >
                <div className="mw">
                    <div className="container">
                        <div className="copyright-bar">Copyright © 2022 <a href="/"><span>BeerTime</span></a>. All Rights Reserved. <Link  to={`/${i18n.language}/rules`}>  {t("კონფიდენციალურობის პოლიტიკა")}</Link></div>
                        <div className="footer-links">
                            <div style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',

                            }}>
                                <a href="https://apps.apple.com/ge/app/beertimeapp/id1658951231" target="_blank">
                                    <img src={AppStore}  alt="logo"  width="125"/>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="https://play.google.com/store/apps/details?id=com.beertime.app" target="_blank">
                                    <img src={GooglePlay} alt="logo"   width="125"/>

                                </a>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>

    )
}

export default Footer;
