import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import classes from './changePassword.modal.scss';

import useUser from "../../../store/hooks/useUser";
import {utils} from "../../../utils";
import {API} from "../../../store";
import Toast from "../../../store/helpers/toast"
import {UseRecovery} from "../../../store/hooks";
import {useTranslation} from "react-i18next";
export const ChangePasswordModal = ({ onCancel,onSuccess,  ...props }) => {
    const {t} = useTranslation()
    const [loader,setLoader] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [rePassword, setRePassword] = useState("");
    const [rePasswordError, setRePasswordError] = useState("");

    const [showPass,setShowPass]=useState(false)
    const [passType, setPassType] = useState({
        pass1:'password',
        pass2:'password'
    });
    const isEnabledSignIn=()=>{
        if(props?.recoveryType ==="changePassword"){
            return  oldPassword !=="" && oldPasswordError ==="" &&
                password !=="" && passwordError ==="" &&
                rePassword !=="" && rePasswordError ===""
        }

        return  password !=="" && passwordError ==="" &&
            rePassword !=="" && rePasswordError ===""
    }

    const togglePassType=(pass)=>{
        if(pass === 'pass1'){
            setPassType(passType.pass1 === 'text'?{...passType,pass1:'password'}:{...passType,pass1:'text'})
        }else{
            setPassType(passType.pass2 === 'text'?{...passType,pass2:'password'}:{...passType,pass2:'text'})
        }

    }

  return (
    <Modal contentClassName="modal-restyle" onHide={onCancel} {...props}  draggable >
      <Modal.Header closeButton>
        <Modal.Title className={classes.title}>{t("პაროლის ცვლილება")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <Form className={classes.form} onSubmit={(e)=>{
          e.preventDefault();
            if(isEnabledSignIn()){

                if( props?.recoveryType ==="changePassword"){
                    API.User.updatePassword({
                        data: {
                            oldPassword:oldPassword,
                            password:password
                        },
                        loader:setLoader
                    }).then(response=>{
                        Toast.show({
                            type:response.status?'success':'error',
                            text1:response.data,
                            autoHide:true,
                            visibilityTime:2000
                        });
                        if(response.status){
                          onCancel()
                        }

                    })
                }else{
                    API.Guest.ChangePassword({
                        data:{
                            password:password
                        },
                        loader:setLoader
                    }).then(response=>{
                        if(response.status){

                            Toast.show({
                                type:'success',
                                text1:response.data,
                                autoHide:true,
                                visibilityTime:1500,
                                onHide:()=>{

                                }

                            })
                            onCancel();
                        }else {
                            Toast.show({
                                type:'error',
                                text1:response.data,
                                autoHide:true,
                                visibilityTime:2000
                            })
                        }
                    })
                }


            }else{
                Toast.show({
                    type:'error',
                    text1:t("შეავსეთ ყველა ველი"),
                    autoHide:true,
                    visibilityTime:2000
                })
            }
        }} >
            {
                props?.recoveryType ==="changePassword" && <Form.Group className="form-box">

                    <div className="input-label">
                        <Form.Control value={oldPassword} type={!showPass?"password":"text"}  isInvalid={oldPasswordError !==""} onChange={e=>{
                            let value= e.target.value;
                            utils.validatePassword(value,setOldPasswordError)
                            setOldPassword(value)
                        }}/>
                        <Form.Label>ძველი პაროლი</Form.Label>
                    </div>
                    <div style={{color:'red',fontSize:'0.875em'}}>
                        {oldPasswordError}
                    </div>
                </Form.Group>
            }


            <Form.Group className="form-box">

                <div className="input-label">
                    <Form.Control value={password} type={passType.pass1}  isInvalid={passwordError !==""} onChange={e=>{
                        let value= e.target.value;
                        utils.validatePassword(value,setPasswordError)
                        setPassword(value)
                    }}/>
                    <Form.Label>ახალი პაროლი</Form.Label>
                    <div className={`toggle-password ${passType.pass1==='text'?'active':'hide'}`} onClick={()=>{togglePassType('pass1')}}/>
                </div>
                <div style={{color:'red',fontSize:'0.875em'}}>
                    {passwordError}
                </div>
            </Form.Group>
          <Form.Group className="form-box">

            <div className="input-label">
              <Form.Control value={rePassword} type={passType.pass2}  isInvalid={rePasswordError !==""} onChange={e=>{
                  let value= e.target.value;
                  utils.validatePasswords(value,password,setRePasswordError)
                  setRePassword(value)
              }}/>
                <Form.Label>გაიმეორეთ პაროლი</Form.Label>
                <div className={`toggle-password ${passType.pass2==='text'?'active':'hide'}`} onClick={()=>{togglePassType('pass2')}}/>
            </div>
            <div style={{color:'red',fontSize:'0.875em'}}>
              {rePasswordError}
            </div>

          </Form.Group>
          <Stack
              className="btn_box"
            direction={'horizontal'}
            style={{ justifyContent: 'flex-end' , marginTop:"30px"}}
            gap={4}
          >
              <Button variant={'secondary'} onClick={onCancel}>გაუქმება</Button>
              <Button variant={'success'} type={"submit"}>შეცვლა</Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  show: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func,
};
ChangePasswordModal.defaultProps = {
  show: true,
  centered: true,
  initialTab: 'password',
};
