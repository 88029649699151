import React, {useRef, useState, useTransition} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import {Navigation, Pagination} from "swiper";
import _ from "lodash";
import {NewCard} from "../card/newCard";

import "./cardSlider.scss"

import {useTranslation} from "react-i18next";
import {useFavourite} from "../../store/hooks/useFavourite";
const CardSlider = ({title, products, onSelectedCategory, type,id,onRefresh}) =>{
	const {t} = useTranslation()
	const favHook = useFavourite()
	const config = {
		//2:{
		//	slidesPerView:3
		//}
	}


	return (


		<div className={"product-slider"} data-type={type} data-id={id}>
				<div className={"product-slider-container"}>
				<div className="title">
					<h3>{title}</h3>
					<span onClick={onSelectedCategory}>{t("ყველას ნახვა")}</span>
				</div>
				<div>
					<Swiper
						slidesPerView={config[id]? config[id].slidesPerView:4}
						spaceBetween={30}
						pagination={{
							clickable: true,
						}}
						navigation={true}

						modules={[ Pagination,Navigation]}
						className={`beertime-slider ${id===2?"favorite":''}`}
						breakpoints={{
							1000: {slidesPerView: config[id]? config[id].slidesPerView:4},
							767: {slidesPerView: 3,spaceBetween: 10},
							400: {slidesPerView: 2,spaceBetween: 10},
							10: {slidesPerView: 1}
						}}
					>
						{
							_.map(products,(v,k)=>{
								return (
									<SwiperSlide key={k}>
										<NewCard v={v} onClick={()=>{
											if(v.isFavourite){
												favHook.removeFavourite(v.product_id,k).then(()=>onRefresh())
											}else{
												favHook.addFavourite(v.product_id).then(()=>onRefresh())
											}
										}}/>
									</SwiperSlide>
								)
							})
						}

					</Swiper>
				</div>

			</div>
		</div>

	)
}
export default CardSlider;