import {PING} from "./userTypes";

const initialState={
    isLogged:false,
    isLoaded:false,
    data:{
        "firstName": "",
        "idNumber": "",
        "lastName": "",
        "phone": "",
        "picture": "",
        "username": "",
    }
}


const userReducer = (state=initialState,action)=>{
    switch (action.type){
        case PING:
            return {...state,...action.payload}
        default: return {...state}
    }
}

export default userReducer;
