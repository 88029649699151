import Image from "../assets/img/profile.png";

const myProfile = {
    name: "DAVIT KALIASHVILI",
    profile_image:Image,
    address: "No. 88, Jln Padungan, Kuching"
}


export default {
    myProfile,

}