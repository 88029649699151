import {UseEvent} from "./index";

const UseApi = ()=>{
    const api = UseEvent()
    const getUser = (callback)=>{
        api.emit("api",{
            data:"user",
            callback:callback
        })
    }
    return {getUser}
}
export default UseApi;
