import {GET_FAVOURITES,ADD_FAVOURITE,DELETE_FAVOURITE} from "./favouriteTypes";
import {API} from "../../index";

export  const GetFavourites =({loader})=>(dispatch)=>{
    API.Product.Favourite({loader:loader}).then(response=>{
        return dispatch({
            type:GET_FAVOURITES,
            payload:response.status?response.data:[]
        })
    })
}
export const AddFavourite = (id)=>(dispatch)=>{

    return new Promise(resolve=>{
        API.User.addFavourite(id).then(response=>{

            if(response.status){
                dispatch(GetFavourites({loader:null}))
                resolve(response)
            }

        })
    })

}
export const DeleteFavourite = (id,index)=>(dispatch,prevState)=>{
    let favourites = prevState().Favourites;
    return new Promise((resolve) => {
        API.User.removeFavourite(id).then(response=>{
            if(response.status){
                if(!index){
                    dispatch(GetFavourites({loader:null}))
                }else{
                    favourites.splice(index,1)
                    dispatch({
                        type:DELETE_FAVOURITE,
                        payload:favourites
                    })
                }

            }
            resolve(response) ;

        })
    })

}