import {query_string} from "../helpers/query_params";
import Client from "../Client";
import Config from "../config/Config";
const Ping  = (loader) =>{
     return Client.get({url: Config.GUEST.CHECK,loader:loader})
}

const SignIn = ({data,loader})=>{
    const {username,password}= data;
   return Client.post({
        url: Config.GUEST.SIGN_IN, data: query_string({
            "username": username,
            "password": password
        })
    })
}

const SignUp = ({data,loader})=>{
    return Client.post({
        url: Config.GUEST.SIGN_UP, data: query_string(data),
        loader:loader
    })
}

const RecoverPassword = ({data,loader}) => {
    return Client.post({
        url: Config.GUEST.RECOVER_PASSWORD, data: query_string(data),
        loader:loader
    })
}
const ChangePassword = ({data,loader}) => {
    return Client.post({
        url: Config.GUEST.CHANGE_PASSWORD, data: query_string(data),
        loader:loader
    })
}

export  {
    Ping,
    SignIn,
    SignUp,
    RecoverPassword,
    ChangePassword
}
