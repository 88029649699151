import {useEffect, useState} from "react";

export function useOutsideAlerter(onClose) {

     const [ref,setRef]=useState(null)
    useEffect(() => {


        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                    console.log(event.target)
                if( event.target.id  !=="user-box" && !ref.current.classList.contains("user_box")){
                    onClose()
                }
            }
        }
        if(ref){

            document.addEventListener("mousedown", handleClickOutside);

        }

        // Bind the event listener
        return () => {
            if(ref){
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            }

        };
    }, [ref]);

    const listener = (ref)=>{
        console.log(ref)
        setRef(ref);
    }

    return  {listener};
}
