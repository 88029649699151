import {DELETE_NOTIFICATIONS, GET_NOTIFICATIONS, READ_NOTIFICATIONS, UNREAD_NOTIFICATIONS} from "./notificationTypes";
import {API} from "../../index";

export  const GetNotifications =({loader,lang})=>(dispatch)=>{
    API.User.notifications({loader:loader}).then(response=>{
        return dispatch({
            type:GET_NOTIFICATIONS,
            payload:response.status?response.data:[]
        })
    })
}

export const ReadNotification = (id,index)=>(dispatch,prevState)=>{
    let notifications = prevState().Notifications.Notifications;
    API.User.readNotification(id).then(response=>{
        if(response.status){
            notifications[index].status=1;
            return dispatch({
                type:READ_NOTIFICATIONS,
                payload:notifications

            })
        }

    })
}
export const DeleteNotification = (id,index)=>(dispatch,prevState)=>{
    let notifications = prevState().Notifications.Notifications;
    return new Promise((resolve) => {
        API.User.deleteNotification(id).then(response=>{
            if(response.status){
                notifications.splice(index,1)
                dispatch({
                    type:DELETE_NOTIFICATIONS,
                    payload:notifications
                })
            }
            resolve(response) ;

        })
    })

}

export const UnreadNotifications = ()=>(dispatch,prevState)=>{
    return new Promise((resolve) => {
        API.User.getUnreadNotifications().then(response=>{
            if(response.status){
                dispatch({
                    type:UNREAD_NOTIFICATIONS,
                    payload:response.data
                })
            }
            resolve(response) ;

        })
    })

}