import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import './caartModal.scss'

import {utils} from "../../../utils";
import {API} from "../../../store";
import Toast from "../../../store/helpers/toast"
import {UseOTP, UseSignIn, UseUser} from "../../../store/hooks";
import moment from "moment";
import {useOrder} from "../../../store/hooks/useOrder";
import {onHidden} from "web-vitals/dist/modules/lib/onHidden";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";
import _ from "lodash";
import {Cart} from "../../index";
import {useTranslation} from "../../../store/i18n/i18n";
import {COLORS} from "../../../constants";
export const CartModal = ({ onCancel,onSuccess,  ...props }) => {
	const nav = useNavigate()
	const {cart,updateCart} = useOrder();
	const {t} = useTranslation()
	const {User} = UseUser()
	const {spaces,createOrder,getSpaces} = useOrder()
	const [loader,setLoader]=useState(false)
	const [dateTime,setDateTime] = useState({
		date:new Date(),
		time:new Date()
	})
	const [show,setShow]=useState(false)
	const [space,setSpace]= useState(1)
	const [date,setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
	const [time,setTime] = useState(moment(new Date()).utcOffset("+0400").format("HH:mm"))
	const [guestQty,setGuestQty] = useState(1)
	useEffect(()=>{
		getSpaces()
	},[])

	const onReservation=()=>{

		createOrder({
			order_date:date,
			order_time:time,
			guestQty:guestQty,
			space_id:space
		},setLoader)
			.then(response=>{

				if(response.status){

					onCancel()
					nav(`/${i18n.language}/orders`)
					Toast.show({
						type:"success",
						text1:response.data,
						autoHide:time,
						visibilityTime:2000
					})

				}else{
					Toast.show({
						type:"error",
						text1:response.data,
						autoHide:time,
						visibilityTime:2000
					})
				}

			})
	}

	return (
		<Modal contentClassName="cart_modal" onHide={onCancel} {...props}  draggable >
			<Modal.Header closeButton>
				<Modal.Title>{t("კალათა")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					User.isLogged && _.size(cart)>0 && <><div style={{color:COLORS.primary}}>{t("ოპერატორი მოგვიანებით დაგიკავშირდებათ, შეკვეთის დეტალების დასაზუსტებლად")}</div><br/> </>
				}

				<div className="cart">
					{
						_.size(cart)>0?  <Cart data={cart} onCancel={onCancel}/>:<div style={{ padding:'20px 0'}}>{t("კალათა ცარიელია")}</div>
					}
				</div>

			</Modal.Body>

		</Modal>
	);
};

CartModal.propTypes = {
	show: PropTypes.bool,
	centered: PropTypes.bool,
	onCancel: PropTypes.func,
};
CartModal.defaultProps = {
	show: true,
	centered: true,
	initialTab: 'password',
};
