import React, {useEffect, useRef, useState, useTransition} from 'react';
import './header.scss';
import {UseGuest, UseReservation, UseSignIn, UseSignUp, UseUser} from "../../store/hooks";
import {Link, useLocation} from "react-router-dom";
import {Modal} from 'react-bootstrap'
import {Button} from "react-bootstrap";
import {i18n, useTranslation} from "../../store/i18n/i18n";
import icons from "../../constants/icons";
import {useOrder} from "../../store/hooks/useOrder";
import _ from "lodash";
import Config from "../../store/config/Config";
import dummyData from "../../constants/dummyData";
import {COLORS} from "../../constants";
import QRCode from "react-qr-code";
import UseCartModal from "../../store/hooks/useCartModal";
import {useNotifications} from "../../store/hooks/useNotifications";
import LogoKa from "../../assets/icons/logo/logo-beertime-ge.png"
import LogoEn from "../../assets/icons/logo/logo-beertime-en.png"

import Icons from "../../constants/icons";
import {useOutsideAlerter} from "../../store/hooks/useOutSideClick";
import {AppStore, GooglePlay} from "../../assets/icons"
const Header = ({onClick}) =>{
    const location = useLocation();

    const [infoModalDetails,setInfoModalDetails]= useState(false);
    const {t} = useTranslation();
    const {cart} = useOrder();
    const cartModal = UseCartModal();
    const  {unreadNotifications,unreadNotificationCount} = useNotifications()
    const [loaded,setLoaded] = useState(false)
    const [mobNav,setMobNav] = useState(false)
    const [qrModal,setQrModal] = useState(false)
    const reservationModal = UseReservation();
    const {User,myCard,logout,CheckSession,UpdateStatus} = UseUser();
    const signInModal = UseSignIn();
    const signUpModal = UseSignUp();
    const [data,setData]=useState(null)
    const [dropDawn,setDropDawn]=useState(false)
    const ref = useRef()

    const closeHandler = ()=>{
        console.log("close")
        setDropDawn(false)
    }

    const {listener} = useOutsideAlerter(closeHandler);
    useEffect(()=>{
        if(User.isLogged){
            myCard()
                .then(response=>{
                    //console.log("data",response.data)
                    setData(response.status? {...response.data}:null)
                })
            unreadNotifications()
        }

    },[User.isLogged])

    useEffect(()=>{
        CheckSession().then(response=>{
            if(response?.status){

                UpdateStatus({
                    loaded:true,
                    data:response?.data,
                    isLogged:response?.status
                })

            }
        }).finally(()=>setLoaded(true))
    },[])

    useEffect(()=>{
        console.log(ref)
        if(ref.current){
            listener(ref)
        }
    },[dropDawn])

    const infoModal = () =>{
        return infoModalDetails && (
            <Modal  centered={true} className="qr_modal" show={true} fullscreen={window.innerWidth<700} onHide={() => setInfoModalDetails(false)} style={{marginTop:30}}>
                <Modal.Header closeButton />

                <Modal.Body className={"qr-modal-body"}>
                    <div>
                        <div>
                            <img src={icons.infoCashbackIcon} width={50}/>
                        </div>
                        <div style={{marginTop:30}}>
                            <p>{t("ყოველი თანხის გადახდისას, დააგროვე თანხის პროცენტი აპლიკაციის ბალანსზე. გამოიყენე დაგროვებული თანხა ნებისმიერ დროს, შემდეგ გადახდებზე")}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const QRModal=()=> {
        return qrModal && (
            <Modal className="qr_modal" show={true} fullscreen={window.innerWidth<700} onHide={() => setQrModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("ჩემი ბარათი")}</Modal.Title>
                </Modal.Header>

               <Modal.Body className={"qr-modal-body"} style={{
                   paddingBottom:100
               }}>
                   <div className="qr_bg" style={{position:'relative'}}>
                       <div className="qr_bg_code">
                           <QRCode value={data?.card?.cardNumber.toString()} size={200} />
                       </div>
                       <div className="qr_username">
                           <p style={{marginBottom:0}}>{User?.data?.firstName} {User?.data?.lastName}</p>
                           <span>{data?.card?.cardNumber}</span>
                       </div>
                       <div style={{
                           position:"absolute",
                           bottom:"30px",
                           right:"30px",
                           cursor:'pointer'
                       }}
                       onClick={()=>setInfoModalDetails(true)}
                       >
                           <img src={icons.infoIcon} alt="" width={"25"}/>
                       </div>
                   </div>

                   {
                       data?.balance && _.size(data.balance)>0 && (
                           <div className="qr_cashback">
                               <div>{t("ქეშბექი")}</div>
                               <div>{data?.balance[0].balance} ₾</div>
                           </div>
                       )
                   }
                   {
                       data?.card  && (
                           <div className="qr_percent">
                               <div>{t("პროცენტი")}</div>
                               <div>{data?.card?.percent} %</div>
                           </div>
                       )
                   }
               </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            {
                infoModal()
            }
            <div className='header'>

                <div className='header-wrap container header-web'>
                    <div className="logo">
                        <a href={`/${i18n.language !=='ka'?i18n.language:"ka"}`}>
                            <img src={i18n.language ==='ka'?LogoKa:LogoEn} width="130"  style={{
                                marginBottom:10
                            }}/>
                        </a>
                    </div>
                    <nav>
                        <ul style={{margin:0}}>
                            <li><Link to={`/${i18n.language}`}>{t('მთავარი')}</Link></li>
                            {/*{User.isLogged ? <li><Link to={`/${i18n.language}/notifications`}>{t('კერძები')}</Link></li>:'' }*/}
                            {/*<li><Link to={`/${i18n.language}/favourites`}>ჩვენს შესახებ</Link></li>*/}
                            <li><Link to={`/${i18n.language}/contact`}>{t('კონტაქტი')}</Link></li>
                        </ul>
                    </nav>

                    {
                        loaded && (
                            <ul className="widget-container">
                                {
                                    User.isLogged ? (
                                        <>

                                            <div className={'user-widget-container'}>
                                                <li className="orderBtn" >
                                                    <Button  onClick={()=>{
                                                        reservationModal.open({
                                                            onSuccess:()=>CheckSession()
                                                        })
                                                    }}>
                                                        {t('დაჯავშნა')}
                                                    </Button>
                                                </li>
                                                <div className="user_box" onClick={()=> setDropDawn(!dropDawn)} id="user-box">


                                                    <div className="user_info" id="user-box">
                                                        <p id="user-box">{User?.data?.firstName + " " + User?.data?.lastName}</p>
                                                        <span id="user-box">{t('ჩემი პროფილი')}</span>
                                                    </div>
                                                    <div id="user-box" className="user_avatar" style={{position:'relative',background:`#ccc url(${(User?.data?.picture)?Config.IMAGE_URI + User?.data?.picture:dummyData.myProfile.profile_image})`}}>
                                                        {
                                                            unreadNotificationCount>0 && <div style={{display:'flex',justifyContent:'center',top:-10,right:0,position:'absolute',backgroundColor:'red',color:'white',alignItems:'center',fontSize:10,borderRadius:'50%',width:14,height:14}}><i>{unreadNotificationCount}</i></div>
                                                        }
                                                    </div>

                                                </div>

                                                {
                                                    dropDawn && <div className="user_drop_down_menu  open" ref={ref}>
                                                        <ul>
                                                            <li>
                                                                <Link to={`/${i18n.language}/profile`} onClick={()=> setDropDawn(!dropDawn)}>
                                                                    <i className="fa-solid fa-user"/>
                                                                    <span>{t('პროფილი')}</span>
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link to={`/${i18n.language}/favourites`} onClick={()=> setDropDawn(!dropDawn)}>
                                                                    <i className="fa-solid fa-heart"/>
                                                                    <span>{t('ფავორიტები')}</span>
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link to={`/${i18n.language}/orders`} onClick={()=> setDropDawn(!dropDawn)}>
                                                                    <i className="fa-solid fa-list-check"/>
                                                                    <span>{t('ჯავშნები')}</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={`/${i18n.language}/notifications`} onClick={()=> setDropDawn(!dropDawn)} style={{position:'relative'}}>
                                                                    <i className="fa-solid fa-comments"/>
                                                                    <span>{t('შეტყობინებები')}</span>
                                                                    {
                                                                        unreadNotificationCount>0 && <div style={{display:'flex',justifyContent:'center',top:0,left:0,position:'absolute',backgroundColor:'red',color:'white',alignItems:'center',fontSize:10,borderRadius:'50%',width:14,height:14}}><i>{unreadNotificationCount}</i></div>
                                                                    }
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a onClick={()=>{
                                                                    setQrModal(true)
                                                                    setDropDawn(!dropDawn)
                                                                }}>
                                                                    <i className="fa-solid fa-qrcode" />
                                                                    <span>{t('ჩემი ბარათი')}</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a onClick={()=> {
                                                                    setDropDawn(!dropDawn)
                                                                    cartModal.open({
                                                                        onSuccess:()=>CheckSession()
                                                                    })
                                                                }}>
                                                                    <div className="cart_icon">
                                                                        <img src={icons.cart} width="16" />
                                                                        <i count={_.size(cart)}/>
                                                                    </div>
                                                                    <span>{t('კალათა')}</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="exit" onClick={()=>{
                                                                    logout()
                                                                    setDropDawn(!dropDawn)
                                                                }}>
                                                                    <span>{t('გასვლა')}</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }

                                            </div>
                                            {/*<div className={'user-widget-container'}>
                                                <div>

                                                    <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                        <i className="fa-solid fa-magnifying-glass"/>
                                                    </Button>

                                                </div>
                                                <div>
                                                    <Link to={`/${i18n.language}/profile`}>

                                                        <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                            <i className="fa-solid fa-user"/>
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={`/${i18n.language}/favourites`}>
                                                        <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                            <i className="fa-solid fa-heart"/>
                                                        </Button>
                                                    </Link>
                                                </div>

                                                <div>
                                                    <Link to={`/${i18n.language}/orders`}>
                                                        <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                            <i className="fa-solid fa-list-check"/>
                                                        </Button>
                                                    </Link>
                                                </div>

                                                <div>
                                                    <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                        <img src={icons.cart} width="18" />
                                                        <i count={_.size(cart)}/>
                                                        <div className="cart">
                                                            {
                                                                _.size(cart)>0 &&  <Cart data={cart}/>
                                                            }
                                                        </div>
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button variant="outline-secondary" className={"user-widget-container-btn"}>
                                                        <i className="fa-solid fa-qrcode" onClick={()=>setQrModal(true)}/>
                                                    </Button>
                                                </div>
                                            </div>*/}

                                        </>
                                    ):(
                                        <>

                                            <li className="orderBtn" onClick={()=>signInModal.open({
                                                onSuccess:()=>CheckSession()
                                            })}>
                                                <Button style={{
                                                    borderRadius:30,
                                                }} >
                                                    {t('დაჯავშნა')}
                                                </Button>
                                            </li>
                                            <li className="signInBtn" onClick={()=>signInModal.open({
                                                onSuccess:()=>CheckSession()
                                            })}>
                                                <button>{t('შესვლა')}</button>
                                                {/*<img alt="user" src={user}/>*/}
                                            </li>
                                            <li className="registrationBtn" onClick={()=> signUpModal.open({
                                                onSuccess:()=>CheckSession()
                                            })}>
                                                <button>{t('რეგისტრაცია')}</button>
                                                {/*<img alt="user" src={userPlus}/>*/}
                                            </li>
                                        </>
                                    )
                                }
                                <li className="registration" onClick={()=> {

                                }}>
                                    <Button onClick={()=>{
                                        cartModal.open({
                                            onSuccess:()=>CheckSession()
                                        })
                                    }} style={{
                                        padding:10
                                    }}>
                                        <div className="cart_icon" style={{position:'relative', width:'20px'}}>
                                            <i>{Icons.CartIcon()}</i>
                                            {
                                                _.size(cart)>0 &&
                                                (
                                                    <div style={{display:'flex',justifyContent:'center',top:-10,left:-10,position:'absolute',backgroundColor:'red',color:'white',alignItems:'center',fontSize:10,borderRadius:'50%',width:14,height:14}}>
                                                        <i>{_.size(cart)}</i>
                                                    </div>
                                                )

                                            }
                                        </div>

                                    </Button>
                                </li>
                            </ul>
                        )
                    }
                </div>
                <div className={"header-wrap mw header-mob"}>

                    <div onClick={()=>setMobNav(true)}>
                        <i className="fa-solid fa-bars"/>
                    </div>
                    <div className="logo">
                        <a href="/">
                            <img src={i18n.language ==='ka'?LogoKa:LogoEn} width="130"  style={{
                                marginBottom:10
                            }}/>
                        </a>
                    </div>
                    <div style={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center'
                    }}>
                        {/*<Button onClick={()=>{
                            cartModal.open({
                                onSuccess:()=>CheckSession()
                            })
                        }} style={{
                            padding:10,
                            backgroundColor:'transparent',
                            borderBlockColor:'transparent'
                        }}>


                        </Button>*/}
                        <div className="mob_cart_icon" style={{position:'relative'}} onClick={()=>{
                            cartModal.open({
                                onSuccess:()=>CheckSession()
                            })
                        }}>
                            <i className={"mob_cart_icon_item"}>{Icons.CartIcon()}</i>
                            {
                                _.size(cart)>0 && <div style={{display:'flex',justifyContent:'center',top:-3,left:-3,position:'absolute',backgroundColor:'red',color:'white',alignItems:'center',fontSize:8,borderRadius:'50%',width:14,height:14}}><i style={{fontSize:12}}>{_.size(cart)}</i></div>

                            }
                        </div>
                        {
                            loaded && User.isLogged? (
                                <i className="fa-solid fa-qrcode" onClick={()=>setQrModal(true)}/>
                            ):(
                                <div className="user" onClick={()=>signInModal.open({onSuccess:()=>CheckSession()})}>
                                    <button>{t('შესვლა')}</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {
                mobNav && <div className="mob_nav">
                    <div className="mob_nav_back" onClick={()=>setMobNav(false)}/>
                    <div className="mob_nav_list" style={{backgroundColor:COLORS.primary, overflowY:'scroll'}}>
                        <div className="mob_nav_head">
                            <div className="logo"><a href="/"> <img src={i18n.language ==='ka'?LogoKa:LogoEn} width="130"  /></a></div>
                            <div className="mob_nav_close" onClick={()=>setMobNav(false)}>X</div>
                        </div>
                        {
                            loaded && User.isLogged  && (
                                <div className="user_box">

                                    <div className="user_avatar" style={{background:`url(${(User?.data?.picture)?{uri:Config.IMAGE_URI + User?.data?.picture}:dummyData.myProfile.profile_image})`}}/>
                                    <div className="user_info">
                                        <p>{User?.data?.firstName + " " + User?.data?.lastName}</p>
                                        <Link to={`/${i18n.language}/profile`} onClick={()=>setMobNav(false)} style={{textDecoration:'unset',}}>
                                            <span style={{color:'white'}}>{t('ჩემი პროფილი')}</span>
                                        </Link>
                                    </div>
                                </div>
                            )
                        }

                        <ul style={{margin:0}}>
                            <li><Link to={`/${i18n.language}`} onClick={()=>setMobNav(false)}>{t('მთავარი')}</Link></li>
                            {
                                loaded && User.isLogged  && (
                                    <>
                                        <li><Link to={`/${i18n.language}/orders`} onClick={()=>setMobNav(false)}>{t('ჯავშნები')}</Link></li>
                                        <li><Link to={`/${i18n.language}/notifications`} onClick={()=>setMobNav(false)}>{t('შეტყობინება')}</Link></li>
                                        <li><Link to={`/${i18n.language}/favourites`} onClick={()=>setMobNav(false)}>{t('ფავორიტები')}</Link></li>
                                    </>
                                )
                            }
                            <li><Link to={`/${i18n.language}/contact`} onClick={()=>setMobNav(false)}>{t('კონტაქტი')}</Link></li>
                            <li>
                                <br/>
                                <div className="language">
                                    <a href={"/ka".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))}  data-lang="ka"/>
                                    <a href={"/ru".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))}  data-lang="ru"/>
                                    <a href={"/en".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))}  data-lang="en"/>
                                </div>
                            </li>
                            <li>
                                <br/>
                                <p style={{margin:0}}>{t("სამუშაო დრო: 10:00 - 02:00")}</p>
                            </li>
                            <li>
                                <br/>
                                <strong style={{margin:0}}>{t("მაგიდის დაჯავშნა")}</strong>
                                <p style={{margin:'5px 0'}}>{t("მაგიდის დაჯავშნა შესაძლებელია საიტიდან ან დარეკეთ ნომერზე")}</p>
                                <a  href="tel:+995511202020">
                                      <span style={{color:'#000',fontWeight:'bold'}}>
                                         <i className="fa-solid fa-mobile-screen-button" style={{color:'#000',marginRight:'10px'}}/> +995 511 202020
                                    </span>
                                </a>

                            </li>
                            <li>
                                <br/>
                                <span>
                                    <i className="fa-solid fa-location-dot" style={{color:'#000',marginRight:'10px'}}/> {t("ალ. ყაზბეგის 12ბ")}
                                </span>
                            </li>
                            <li>
                                <div style={{
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center',
                                    width:"80%"
                                }}>
                                    <a href="https://apps.apple.com/ge/app/beertimeapp/id1658951231" target="_blank" style={{
                                        flex:1,
                                        aspectRatio:2
                                    }}>
                                        <img src={AppStore}  alt="logo"  width="100%"  height={"30"}/>
                                    </a>
                                    &nbsp;
                                    <a href="https://play.google.com/store/apps/details?id=com.beertime.app" target="_blank" style={{
                                        flex:1,
                                        aspectRatio:2
                                    }}>
                                        <img src={GooglePlay} alt="logo"   width="98%" height={"30"}/>

                                    </a>

                                </div>
                            </li>
                        </ul>



                        <div className="mob_nav_footer">

                            {
                                loaded && User.isLogged  && (
                                    <div>
                                        <button onClick={()=>{
                                            setMobNav(false)
                                            logout()
                                        }}>{t('გასვლა')}</button>
                                    </div>
                                )
                            }
                            <Link to={`/${i18n.language}/rules`} onClick={()=>setMobNav(false)} style={{color:COLORS.black,fontSize:'0.865rem'}}> {t(`კონფიდენციალურობის პოლიტიკა`)} </Link>

                        </div>

                    </div>
                </div>
            }
            {
                QRModal()
            }
        </>

    )
}

export default Header;
