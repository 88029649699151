import {DELETE_NOTIFICATIONS, GET_NOTIFICATIONS, READ_NOTIFICATIONS,UNREAD_NOTIFICATIONS} from "./notificationTypes";

const initialState={
    unreadNotificationCount:0,
    Notifications:[]
};

const notificationReducer = (state=initialState, action)=>{
    switch (action.type){
        case GET_NOTIFICATIONS:
            return {
                ...state,
                Notifications:[...action.payload]
            }
        case READ_NOTIFICATIONS:
            return {
                ...state,
                Notifications:[...action.payload]
            }
        case DELETE_NOTIFICATIONS:
              return {
                ...state,
                  Notifications:[...action.payload]
            }
        case UNREAD_NOTIFICATIONS:
          return {
              ...state,
              unreadNotificationCount: action.payload
         }
        default: return {...state}
    }
}

export default notificationReducer;
