import {memo, useEffect, useRef, useState} from "react";
import useUser from "../store/hooks/useUser";
import {UseOTP, UseRecovery, UseSignIn} from "../store/hooks";
import {API} from "../store";
import Toast from "../store/helpers/toast";
import {Button, Container, Form, Stack} from "react-bootstrap";
import {utils} from "../utils";
import Config from "../store/config/Config";
import dummyData from "../constants/dummyData";
import {COLORS} from "../constants";
import {useTranslation} from "react-i18next";
import '../assets/profile.scss';
import {useNavigate, useParams} from "react-router-dom";

const ProfileScreen = () =>{

	const navigate = useNavigate();
	const {lang='ka'}=useParams()
	const ref= useRef();
	const {t} = useTranslation()
	const recovery = UseRecovery()
	const {User,UploadProfilePicture,CheckSession} = useUser();
	const [loader,setLoader]=useState(false)
	const [showPass,setShowPass]=useState(false)

	const [firstName,setFirstName]=useState(User.data.firstName);

	const [firstNameError, setFirstNameError] = useState("");

	const [lastName,setLastName]=useState(User.data.lastName);
	const [lastNameError, setLastNameError] = useState("");

	const [idNumber,setIdNumber]=useState(User.data.idNumber);
	const [idNumberError, setIdNumberError] = useState("");

	const [phone,setPhone]=useState(User.data.phone);
	const [phoneError, setPhoneError] = useState("");

	const isEnabledSignIn=()=>{
		return (
			firstName !== "" && firstNameError===""   &&
			lastName !=="" && lastNameError ==="" &&
			idNumber !=="" && idNumberError ==="" &&
			phone !=="" && phoneError ==="" &&
			!loader
		)

	}
	const onChange = (event)=> {
		const reader = new FileReader();
		reader.onload = function(){
			UploadProfilePicture(reader.result.split(";")[1],setLoader)
		}
		reader.readAsDataURL(event.target.files[0]);
	}

	useEffect(()=>{
		setFirstName(User.data.firstName)
		setLastName(User.data.lastName)
		setIdNumber(User.data.idNumber)
		setPhone(User.data.phone)

	},[User])

	useEffect(()=>{

		if(User.isLoaded){
			if(!User.isLogged){
				navigate(`/${lang}`)
			}
		}

	},[User.isLoaded && User.isLogged])
	return <Container className={"main-container"}>
		{
			User.isLogged &&  (
				<div className="profile-page" style={{marginTop:"30px"}}>
					<h4 style={{textAlign:'center',padding:'10px 0'}}>{t("ჩემი პროფილი")}</h4>
					<div className="profile_box">
						<div className="profile_avatar">
							<div className="avatar" style={{background:`#ccc url(${(User?.data?.picture)?Config.IMAGE_URI + User?.data?.picture:dummyData.myProfile.profile_image})`}}/>
							<div className="change_avatar" onClick={()=>ref.current.click()}>{t("შეცვლა")}</div>
							<input type="file" style={{display:"none"}} ref={ref} onChange={onChange} />
						</div>
						<Form className="profile-form" onSubmit={(e)=>{
							e.preventDefault();
							const userData = {
								firstName:firstName,
								lastName:lastName,
								idNumber:idNumber,
								phone:phone,
							}
							if(isEnabledSignIn()){
								API.User.update({
									data:userData,
									loader:setLoader
								}).then(response=>{
									if(response.status){
										Toast.show({
											type:'success',
											text1: response.data.msg,
											autoHide:true,
											visibilityTime:3000
										})
										CheckSession()

									}else{

										switch (response.data.key){
											case 'idNumber':
												setIdNumberError(response.data.msg)
												break;
											case 'phone':
												setPhoneError(response.data.msg)
												break;
											default: Toast.show({
												type:'error',
												text1: response.data,
												autoHide:true,
												visibilityTime:3000
											})
										}
									}
								})
								//
							}
						}} >
							<Form.Group className="form-box">
								<div className="input-label">
									<Form.Control value={firstName} isInvalid={firstNameError!==""} type="text" onChange={e=>{
										let value= e.target.value;
										utils.validateEmpty(value,setFirstNameError)
										setFirstName(value)
									}}/>
									<Form.Label>{t("სახელი")}</Form.Label>
								</div>
								<Form.Control.Feedback type="invalid">{firstNameError}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="form-box">
								<div className="input-label">
									<Form.Control value={lastName}  isInvalid={lastNameError!==""} type="text" onChange={e=>{
										let value= e.target.value;
										utils.validateEmpty(value,setLastNameError)
										setLastName(value)
									}}/>
									<Form.Label>{t("გვარი")}</Form.Label>
								</div>
								<Form.Control.Feedback type="invalid">{firstNameError}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="form-box">
								<div className="input-label">
									<Form.Control  value={idNumber}  isInvalid={idNumberError!==""} type="text" onChange={e=>{
										let value= e.target.value;
										utils.validateEmpty(value,setIdNumberError)
										setIdNumber(value)
									}}/>
									<Form.Label>{t("პირადი ნომერი")}</Form.Label>
								</div>
								<Form.Control.Feedback type="invalid">{idNumberError}</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="form-box">
								<div className="input-label">
									<Form.Control  value={phone}  isInvalid={phoneError!==""} type="number" onChange={e=>{
										let value= e.target.value;
										utils.validatePhone(value,setPhoneError)
										setPhone(value)
									}}/>
									<Form.Label>{t("მობილური")}</Form.Label>
								</div>
								<Form.Control.Feedback type="invalid">{phoneError}</Form.Control.Feedback>
							</Form.Group>

							<Stack className="btn_box action_button" direction={'horizontal'} gap={4} >
								<Button variant={'secondary'} type={"button"} onClick={()=>recovery.open({
									recoveryType:'changePassword'
								})}>{t("პაროლის შეცვლა")}</Button>
								<Button variant={'success'} type={"submit"}>{t("განახლება")}</Button>
							</Stack>
						</Form>
					</div>
				</div>
			)
		}
			<br/><br/><br/>
		</Container>
}
export default memo(ProfileScreen)