
import './App.css';

import {
    Footer,
    Header,
} from "./components/index";
import React, {useEffect, useState} from "react";

import MainRouter from "./screens";
import HeaderContactPanel from "./components/Header/headerContactPanel";
import {MobileNavigation} from "./components";
import {i18n} from "./store/i18n/i18n";
import {Container} from "react-bootstrap";
function App() {
    const [load,setLoad] = useState(false)
    function detectLang() {
        let lang = window.location.pathname.split("/")[1];
        if(lang==="ru"){
            i18n.changeLanguage("ru").then(()=>setLoad(true))
        }else if(lang==="en"){
            i18n.changeLanguage("en").then(()=>setLoad(true))
        }else{
            i18n.changeLanguage("ka").then(()=>setLoad(true))

        }
    }

    useEffect(()=>{
        detectLang()
    },[])
    return load && (
        <>
            <HeaderContactPanel/>
            <Header />
            {/*<Container>*/}
                <MainRouter/>
            {/*</Container>*/}
            <Footer/>
            <MobileNavigation/>
        </>
  );
}



export default App;
