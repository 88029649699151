import {memo, useEffect, useState} from "react";
import {COLORS, icons} from "../constants";
import _ from "lodash";
import {useOrder} from "../store/hooks/useOrder";
import moment from "moment";
import {setSelectedTab} from "../store/tab/tabActions";
import {useDispatch} from "react-redux";
import {Container} from "react-bootstrap";
import '../assets/order.scss';
import {useTranslation} from "../store/i18n/i18n";
import Toast from "../store/helpers/toast";
import {OrderDetailsModal} from "../components/modal/OrderDetailsModal/OrderDetailsModal";
import {UseReservation, UseSignIn, UseUser} from "../store/hooks";

const OrdersScreen = () =>{
	const reservationModal = UseReservation();
	const {User,CheckSession} = UseUser();
	const signInModal = UseSignIn();
	const ordersHook = useOrder()
	const {t} = useTranslation()
	const [loader,setLoader]=useState(false)
	const dispatch = useDispatch()
	const [selected,setSelected] = useState(null)
	useEffect(()=>{
		dispatch(setSelectedTab("orders"))

		ordersHook.getOrders(setLoader)
	},[])
	function renderStatus(status) {
		switch (status){
			case 1: return 'დადასტურებული'
			case -1: return 'ელოდება დადასტურებას'
			case 2: return 'გაუქმებული'
			case 3: return 'გაუქმებული';
			default: return  ""
		}
	}

	return <Container className={"main-container"}>
		<OrderDetailsModal show={selected !==null} data={selected} onCancel={()=>setSelected(null)}/>
		<div className="order-page">
		{

			ordersHook?.orders.length>0?
				<div style={{
					marginTop:20,
					minHeight: `${window.innerHeight}px`

				}}>
					<h4 style={{marginLeft:20}}>{t('ჯავშნები')}</h4>
					<br/>
					<div className="notifications-container">
						{
							_.map(ordersHook.orders,(v,k)=>{
								return <div className={`notification ${v.status ===1? 'read':"unread"}`} key={k} style={{
									display:'flex',
									flexDirection:'row'
								}} >
									<div

										style={{
											flex:1
										}}
										onClick={()=>{

											ordersHook.orderDetails({id:v.id})
												.then(response=>{
													setSelected({
														...v,
														data:response.status?response.data:[]
													})
												})
										}}
									>
										<div style={{
											display:'flex',
											flexDirection:'row',
											justifyContent:'space-between'
										}}>
											<div className="notification-title">{t(v.title)}</div>
											<div  className="notification-date">{  moment(v.timestamp).format("YYYY-MM-DD HH:mm")}</div>
										</div>
										<div style={{
											display:'flex',
											flexDirection:'row',
											justifyContent:'space-between'
										}}>
											<div className="notification-description">{t("სტუმრების რაოდენობა")}: <strong>{v.guestQty}</strong> </div>
											<div className="notification-description"
												 style={{
													 color:v.status===1?COLORS.green: v.status ===-1? COLORS.darkBlue: COLORS.red

												 }}
											>{t(renderStatus(v.status))}</div>


										</div>
									</div>

									<div style={{marginLeft:20,display:'flex',alignItems:'center'}}>
										<div style={{
											color:'red'
										}} onClick={()=>{
											if(window.confirm(t('დარწმუნებული, ხართ რომ გსურთ გაუქმება'))){
												ordersHook.deleteOrder(v.id,k).then(response=>{

													Toast.show({
														type:response.status?'success':'error',
														text1:response.data,
														autoHide:true,
														visibilityTime:2000
													})
												})
											}
										}}>
											<i className="fa fa-close"/>
										</div>
									</div>
								</div>
							})
						}

					</div>
				</div>:

				<div style={{flex:1,justifyContent:'center',alignItems:'center'}}>
					<img src={loader?icons.spinner:icons.nothingFound} style={{height:50,width:50,tintColor:loader?null:COLORS.black}}/>
					<span>{t("შეტყობინებები არ მოიძებნა")}</span>
				</div>
		}

		<div
			className={"order-icon"}
			style={{
			position:'fixed',
			right:20,
			border:"1px solid #ccc",
			borderRadius:50,
			backgroundColor:COLORS.white,
			padding:"1rem",
			cursor:'pointer'
		}}
			 onClick={()=> {
				 if(!User.isLogged){
					 signInModal.open({
						 onSuccess:()=>CheckSession()
					 })
				 }else{
					 reservationModal.open({
						 onSuccess:()=>CheckSession()
					 })
				 }
			 }}
		>
			<img
				src={icons.CalendarCheck}
				alt=""
				width={"35"}

			/>
		</div>


	</div>
	</Container>
}
export default memo(OrdersScreen)
