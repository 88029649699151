import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import './reservation.scss';
import Toast from "../../../store/helpers/toast"
import moment from "moment";
import {useOrder} from "../../../store/hooks/useOrder";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import Config from "../../../store/config/Config";
import {useTranslation} from "../../../store/i18n/i18n";
registerLocale("ka", ka);


export const ReservationModal = ({ onCancel,onSuccess,  ...props }) => {

	const {t} = useTranslation()
	const nav = useNavigate()
	const {spaces,createOrder,getSpaces,updateComment,updateCart} = useOrder()
	const [loader,setLoader]=useState(false)
	const [dateTime,setDateTime] = useState({
		date:new Date(),
		time:new Date()
	})
	const [show,setShow]=useState(false)
	const [space,setSpace]= useState(null)
	const [date,setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
	const [time,setTime] = useState(moment(new Date()).utcOffset("+0400").format("HH:mm"))
	const [guestQty,setGuestQty] = useState(1)
	const [startDate, setStartDate] = useState(new Date());

	useEffect(()=>{
		getSpaces()
	},[])

	const onReservation=()=>{

		createOrder({
			order_date:date,
			order_time:time,
			guestQty:guestQty,
			space_id:space
		},setLoader)
			.then(response=>{
				if(response.status){
					onCancel()
					nav(`/${i18n.language}/orders`)
					updateCart([])
					Toast.show({
						type:"success",
						text1:response.data,
						autoHide:true,
						visibilityTime:2000
					})

				}else{
					console.log(response)
					Toast.show({
						type:"error",
						text1:response.data,
						autoHide:true,
						visibilityTime:2000
					})
				}

			})
	}

	return (
		<Modal contentClassName="reservation-modal" onHide={onCancel} {...props}  draggable >
			<Modal.Header closeButton>
				<Modal.Title> {t("აირჩიეთ სივრცე")}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={(e)=>{
				e.preventDefault();
			}} >
				{
					!space?
					<div className="reservation_wrapper" style={{overflowY:'scroll',display:'flex',flexDirection:'row',flexWrap:'wrap',padding:'5'}}>

						{
							_.map(spaces,(v,k)=>{
								return (
									<div
										onClick={()=>setSpace(v.id)}
										key={k}
										style={{
										display:'flex',
										width:'50%',
										padding:10,
										flexDirection:'column',
										cursor:'pointer'
									}}>
										<span style={{fontFamily:"BPG Arial Caps,sans-serif"}}>{t(v.title)}</span>
										<div style={{
											width:"100%",
											aspectRatio:2,
											backgroundRepeat:'no-repeat',
											backgroundSize:'cover',
											borderRadius:'10px',
											backgroundImage:`url(${Config.IMAGE_URI+v.image})`
										}}>

										</div>
									</div>
								)

							})
						}

					</div>
					:
					<div className="reservation_wrapper" style={{ maxHeight:`calc(100vh -100px),overflowY:scroll`}}>
						<div className="rw-flex">
							<div className="input-label">
								<p>{t("აირჩიეთ თარიღი")}</p>
								<DatePicker
									selected={startDate}
									onChange={(date) => {
										setDateTime({...dateTime,time:moment(date).utcOffset("+0400").format("HH:mm")})
										setDateTime({...dateTime,date:moment(new Date()).format("YYYY-MM-DD")})
										setDate(moment(new Date()).format("YYYY-MM-DD"))
										setStartDate(date)
									}}
									showTimeSelect
									minDate={new Date()}
									locale="ka"
									dateFormat="dd-MM-yyyy hh:mm"
									timeFormat="HH:mm"
								/>

								{/*<input type="date" onChange={(e)=>{

								setDate(e.target.value)
								setDateTime({...dateTime,date:date})

							}}/>*/}
							</div>
							{/*<div className="input-label">
							<p>აირჩიეთ დრო</p>

							<input type="time" onChange={(e)=>{
								setTime(e.target.value)
								setDateTime({...dateTime,time:date})

							}}/>
						</div>*/}
						</div>

						<br/>
						<div className="guest_count">
							<p>{t("სტუმრების რაოდენობა")}</p>
							<div className="guest_count_num">
								<ul>
									{
										_.range(1,15).map(v=>{
											return <li key={v} className={`${guestQty===v?'active':''}`} onClick={()=>setGuestQty(v)}>{v}</li>
										})
									}

									<li   className={`${guestQty==="15+"?'active':''}`} onClick={()=>setGuestQty("15+")}>15+</li>
								</ul>
							</div>

						</div>
						<br/>
						<div className="chose_area">
							<p>{t("სივრცის შეცვლა")}</p>
							<div className="btn-area">
								{
									_.map(spaces, (item,index)=>{
										return <button key={index} className={`${item.id===space?'active':''}`} onClick={()=>setSpace(item.id)} >{t(item.title)}</button>
									})
								}
							</div>


						</div><br/>
						<div className="chose_area">
							<p>{t("დაგვიტოვეთ კომენტარი")}</p>
							<textarea className="comment" id="w3review" name="w3review" rows="4" cols="50" style={{width:'100%'}} onChange={e=>updateComment(e.target.value)}
							/>
						</div>
					</div>

				}


			</Form>
			<Modal.Body>
				<div className="res-btn">
					<Button variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
					{
						space && <Button variant={'success'} type={"submit"} onClick={()=>onReservation()}>{t("დაჯავშვნა")}</Button>
					}
				</div>
				{/*<Button variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
				<Stack direction={'horizontal'} style={{ justifyContent: 'flex-end',marginTop:"1rem" }} gap={4}>{
					space && <Button variant={'success'} type={"submit"} onClick={()=>onReservation()}>{t("დაჯავშვნა")}</Button>

				}
				</Stack>*/}
			</Modal.Body>

		</Modal>
	);
};

ReservationModal.propTypes = {
	show: PropTypes.bool,
	centered: PropTypes.bool,
	onCancel: PropTypes.func,
};
ReservationModal.defaultProps = {
	show: true,
	centered: true,
	initialTab: 'password',
};
