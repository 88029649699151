import Client from "../Client";
import Config from "../config/Config";
const GetAllProducts  = ({loader}) =>{
	return Client.get({url: Config.PRODUCT.GET_PRODUCTS,loader:loader})
}

const Search  = ({query_string,loader}) =>{

	return Client.post({url: Config.PRODUCT.SEARCH,loader:loader,data:{
		query_string:query_string
	}})
}
const Favourite  = ({loader}) =>{
	return Client.get({url: Config.PRODUCT.FAVOURITE,loader:loader})
}

const GetProductById =(id,loader)=>{
	return Client.get({url: Config.PRODUCT.GET_PRODUCT_BY_ID.replace("{id}",id),loader:loader})
}


export  {
	GetAllProducts,
	Search,
	Favourite,
	GetProductById
}