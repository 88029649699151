import { UseEvent,  } from './index';

export default function UseRecovery(){
  const event  = UseEvent();
  const open=(params={})=>{
    event.emit("event",{
      type:"Recovery",
      ...params,
    })
  }

  const recovery=(params={})=>{
    event.emit("event",{
      type:"RecoverPasswordModal",
      ...params
    })

  }

  return  {open,recovery}
}
