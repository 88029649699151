import {GET_FAVOURITES, DELETE_FAVOURITE, ADD_FAVOURITE} from "./favouriteTypes";

const initialState=[];

const favouriteReducer = (state=initialState, action)=>{
    switch (action.type){
        case GET_FAVOURITES:
            return [...action.payload]
        case DELETE_FAVOURITE:
            return [...action.payload]
        case ADD_FAVOURITE:
            return [...action.payload]
        default: return [...state]
    }
}

export default favouriteReducer;
