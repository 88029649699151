import {useDispatch, useSelector} from "react-redux";
import {GetAllProducts} from "../reducers/products/productActions";
import {GET_SELECTED_PRODUCTS} from "../reducers/products/productTypes";
import {API} from "../index";

export const useProduct = ()=> {
	const dispatch = useDispatch();
	const Products = useSelector(state => state.Products)
	const SelectedProducts = useSelector(state => state.SelectedProducts)

	const getProducts = (loader) => {
	  	dispatch(GetAllProducts({loader:loader}))
	}
	const setSelectedProducts=(products)=>{
		dispatch({
			type:GET_SELECTED_PRODUCTS,
			payload:products
		})
	}

	const getProductById=(id,loader)=>{
			return API.Product.GetProductById(id,loader);
	}

	return {Products,getProducts,SelectedProducts,setSelectedProducts,getProductById}
}