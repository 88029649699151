import {memo, useEffect, useState} from "react";
import {COLORS, icons} from "../constants";
import {useNotifications} from "../store/hooks/useNotifications";
import _ from "lodash";
import {setSelectedTab} from "../store/tab/tabActions";
import {useDispatch} from "react-redux";
import {Container} from "react-bootstrap";
import '../assets/notifications.scss';
import {useTranslation} from "../store/i18n/i18n";

const NotificationsScreen = () =>{

	const dispatch = useDispatch()
	const notificationHook = useNotifications()
	const [loader,setLoader]=useState(false)
	const {t} = useTranslation()
	useEffect(()=>{
		dispatch(setSelectedTab("notifications"))

		notificationHook.getNotifications(setLoader)
	},[])

	return <Container className={"main-container"}>
		<div className="notification-page">
		{

				notificationHook?.Notifications.length>0?
					<div style={{
						marginTop:20,
						minHeight: `${window.innerHeight}px`

					}}>
						<h4 style={{marginLeft:20}}>{t("შეტყობინებები")}</h4>
						<br/>
						<div className="notifications-container">
							{
								_.map(notificationHook.Notifications,(v,k)=>{
									return <div className={`notification ${v.status ===1? 'read':"unread"}`} key={k} onClick={()=>notificationHook.readNotification(v.id,k)}>
										<div className="notify-head">
											<div className="notification-title">{v.title}</div>
											<div  className="notification-date">{v.timestamp}</div>
										</div>
										<div className="notification-description">{v.description}</div>
									</div>
								})
							}

						</div>
					</div>:

					<div style={{flex:1,justifyContent:'center',alignItems:'center'}}>
						<img src={loader?icons.spinner:icons.nothingFound} style={{height:50,width:50,tintColor:loader?null:COLORS.black}}/>
						<span>{t("შეტყობინებები არ მოიძებნა")}</span>
					</div>
		}
		</div>
	</Container>
}
export default memo(NotificationsScreen)