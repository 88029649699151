import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Stack, Button } from 'react-bootstrap';
import classes from './RecoverPasswordModal.module.scss';

import useUser from "../../../store/hooks/useUser";
import {utils} from "../../../utils";
import {API} from "../../../store";
import {UseOTP} from "../../../store/hooks";
import UseRecovery from "../../../store/hooks/useRecovery";
import Toast from "../../../store/helpers/toast";
import {useTranslation} from "react-i18next";
export const RecoverPasswordModal = ({ onCancel,onSuccess,  ...props }) => {
    const {t} = useTranslation()
    const otpModal = UseOTP()
    const recover = UseRecovery()
    const {keepAlive} = useUser()
    const [phone,setPhone]=useState("")
    const [phoneError,setPhoneError]=useState("")
    const [loader, setLoader] = useState("");

    const isEnabledSignIn=()=>{
        return phone !== "" && phoneError ===""
    }

    const reCoverPassword = () =>{
        API.Guest.RecoverPassword({
            data: {
                phone:phone
            },
            loader:setLoader
        }).then(response=>{
            if(!response.status){
                if(response.data.key==="otp"){
                    onCancel()
                    otpModal.open({
                        onResend:()=>{
                            reCoverPassword()
                        },
                        onSuccess:(smsCode,error,hide)=>{
                            API.Guest.RecoverPassword({
                                data:{
                                    phone:phone,
                                    otp:smsCode
                                },
                                loader:setLoader
                            }).then(response=>{
                                if(response.status){
                                    hide();
                                    recover.open({
                                        onSuccess:()=>{
                                            console.log("success")
                                        }
                                    })
                                }else{
                                    switch (response.data.key){
                                        case 'phone':
                                            Toast.show({
                                                type:'error',
                                                text1:response.data.msg,
                                                autoHide:true,
                                                visibilityTime:3000
                                            })
                                            break;
                                        case 'otp':
                                            if(response.data.msg !=="otp"){
                                                Toast.show({
                                                    type:'error',
                                                    text1:response.data.msg,
                                                    autoHide:true,
                                                    visibilityTime:3000
                                                })
                                            }
                                            break;
                                        default:
                                    }

                                }


                            })
                            /*recover.open({
								onSuccess:()=>console.log("success")
							})*/

                        }
                    })
                }
            }

        })
    }
  return (
    <Modal contentClassName="modal-restyle"  show={true} onHide={onCancel} {...props}  draggable >
      <Modal.Header closeButton>
        <Modal.Title className={classes.title}>{t("პაროლის აღგენა")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <Form className={classes.form} onSubmit={(e)=>{
          e.preventDefault();
            if(isEnabledSignIn()){
                reCoverPassword()
            }
        }} >
          <Form.Group className="form-box">

              <div className="input-label">
                <Form.Control value={phone} isInvalid={phoneError!==""} type="number" onChange={e=>{
                    let value= e.target.value;
                    utils.validatePhone(value,setPhoneError)
                    setPhone(value)
                }}/>
                  <Form.Label>{t("მობილური")}</Form.Label>
                <Form.Control.Feedback type="invalid">{t(phoneError)}</Form.Control.Feedback>
              </div>
          </Form.Group>

          <Stack
              className="btn_box"
            direction={'horizontal'}
            style={{ justifyContent: 'flex-end' , marginTop:"30px"}}
            gap={4}
          >
              <Button variant={'secondary'} onClick={onCancel}>{t("გაუქმება")}</Button>
              <Button variant={'success'} type={"submit"}>{t("კოდის გაგზავნა")}</Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

RecoverPasswordModal.propTypes = {
  show: PropTypes.bool,
  centered: PropTypes.bool,
  onCancel: PropTypes.func,
};
RecoverPasswordModal.defaultProps = {
  show: true,
  centered: true,
  initialTab: 'password',
};
