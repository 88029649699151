import './slider.scss';
import React, {useEffect, useRef, useState} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Config from "../../store/config/Config"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";

import {API} from "../../store";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {i18n} from "../../store/i18n/i18n";

SwiperCore.use([Autoplay]);

const Slider = () =>{
    const navigate = useNavigate()
    const [slides,setSlides]=useState([])
    useEffect(()=>{
        API.Main.slides(null).then(response=>{
            setSlides(response.status?response.data:[])
        })
    },[])


    return (
        <div className='slider'>
            <Swiper

                spaceBetween={30}
                centeredSlides={true}
                autoplay
                pagination={{
                    clickable: true,
                }}
                loop
                navigation={true}
                modules={[ Pagination, Navigation]}
                className="beertime-slider"
                initialSlide="1"


            >
                {
                    _.map(slides,(v,k)=>(
                        <SwiperSlide key={k} style={{backgroundImage:`url(${Config.IMAGE_URI.concat(v.image)})`,cursor: `${v.url.trim()===""? 'default':'pointer' }`}} onClick={()=>{
                           if(v.url.trim()!==""){
                               navigate(v.url.replace("{lang}",i18n.language))
                           }
                        }}>
                            <div className="border" />
                        </SwiperSlide>
                    ))
                }


            </Swiper>
        </div>
    )
}

export default Slider;
