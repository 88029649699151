import React from 'react';
import PropTypes from 'prop-types';
import { Modal} from 'react-bootstrap';
import './OrderDetails.scss';
import "react-datepicker/dist/react-datepicker.css";
import  { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import {useTranslation} from "react-i18next";
import _ from "lodash"
registerLocale("ka", ka);


export const OrderDetailsModal = ({ onCancel,onSuccess, data,  ...props }) => {
	const {t} = useTranslation()
	console.log(data)

	return (
		<Modal contentClassName="orderDetails" onHide={onCancel} {...props}  draggable >
			<Modal.Header closeButton>
				<Modal.Title> {t(data?.title)}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				 <div className={"order_date"}>
					 <div>{t("ჯავშნის დრო")}:</div>
					 <div>
						 {data?.order_date.concat(" ",data?.order_time)}
					 </div>
				 </div>
				<div  className={"order_guest"}>
					<div>{t("სტუმრების რაოდენობა")}:</div>
					<div>{data?.guestQty}</div>
				</div>

				{
					_.size(data?.data)>0 && (
						<>
							<hr/>
							<div className={"order_details"}>
								{
									_.map(data.data, (detail, key)=>{
										return (
											<div key={key}>
												<div style={{
													display:'flex',
													flexDirection:'row',

												}}>
													<div>{detail.qty}x</div> &nbsp;&nbsp;&nbsp;
													<div>{detail?.title}</div>
												</div>
												<div>{ (detail?.price * detail.qty).toFixed(2)}</div>
											</div>
										)
									})
								}
							</div>

						</>

					)
				}
				<hr/>
				{

					data?.comment && (
						<>
							<div className={"order_comment"}>{data?.comment}</div>
							<hr/>
						</>
					)
				}
				<div className={"order_contact"}>
					{t("Ჯავშნის გასაუქმებლად გთხოვთ დაგვიკავშირდით ნომერზე") }<a href="tel:+995511202020"> +995 511 202020</a>
				</div>


			</Modal.Body>

		</Modal>
	);
};

OrderDetailsModal.propTypes = {
	show: PropTypes.bool,
	centered: PropTypes.bool,
	onCancel: PropTypes.func,
};
OrderDetailsModal.defaultProps = {
	show: true,
	centered: true,
	data:{}
};
