import {useDispatch, useSelector} from "react-redux";
import {AddFavourite, GetFavourites,DeleteFavourite} from "../reducers/favourites/favouriteActions";

export const useFavourite = ()=> {
	const dispatch = useDispatch();
	const Favourites = useSelector(state => state.Favourites)

	const getFavourites = (loader) => {
	  	dispatch(GetFavourites({loader:loader}))
	}
	const addFavourite = (id)=>{

		return dispatch(AddFavourite(id))
	}
	const removeFavourite =(id, index) => {
		return  dispatch(DeleteFavourite(id, index))
	}
	return {Favourites,getFavourites,addFavourite,removeFavourite}
}