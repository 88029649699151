import React from "react"
import  "./headerContactPanel.scss"
import {i18n, useTranslation} from "../../store/i18n/i18n";
import {Link, useLocation} from "react-router-dom";
const HeaderContactPanel = ()=>{
	const {t} = useTranslation()
	const location = useLocation()

	return (
		<div className={"headerContactPanel"}>
			<div className="container">
				<div className="hc_wrapper">
					<div className="left_side">
						<span><i className="fa-solid fa-mobile-screen-button"/>+995 511 202 020</span>
						<span><i className="fa-solid fa-location-dot"/>{t("ალ.ყაზბეგის 12ბ")}</span>
						<Link to={`/${i18n.language}/contact`} style={{color:'white',textDecoration:'none'}} ><i className="fa-solid fa-clock"/> <span style={{color:'white'}}>{t("ორშაბათი-კვირა: 10:00 - 02:00")}</span></Link>

					</div>
					<div className="right_side language">

						{
							i18n.language !=='ka' && <a href={"/ka".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))} data-lang="ka"/>
						}
						{
							i18n.language !=='ru' && <a  href={"/ru".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))} data-lang="ru"/>
						}
						{
							i18n.language !=='en' && <a  href={"/en".concat(location.pathname.replace("/ka","").replace("/ru","").replace("/en",""))} data-lang="en"/>
						}

						{/*<i className="fa-brands fa-facebook"/>&nbsp;&nbsp;
						<i className="fa-brands fa-facebook-messenger"/>&nbsp;&nbsp;
						<i className="fa-brands fa-youtube"/>*/}
					</div>
				</div>
			</div>


		</div>
	)
}
 export default HeaderContactPanel;