import {useDispatch, useSelector} from "react-redux";
import {DeleteOrder, GetOrders, GetSpaces} from "../api/order";
import {API} from "../index";
import {CART, ORDER_MODAL, SET_COMMENT, UPDATE_ORDERS} from "../reducers/orders/orderTypes";

export const useOrder = ()=> {
	const dispatch = useDispatch();
	const {spaces,orders,orderModal,cart,comment} = useSelector(state => state.Orders)
	const getSpaces = (loader) => {
	  	dispatch(GetSpaces({loader:loader}))
	}
	const getOrders = (loader) => {
		dispatch(GetOrders({loader:loader}))
	}
	const createOrder = (data,loader)=>{
		return API.Order.CreateOrder({...data,comment:comment,cart:cart},loader);
	}
	const closeModal=(callback=null)=>{
		dispatch({
			type:ORDER_MODAL,
			payload:false
		})

		if(callback){
			callback?.()
		}

	}
	const openModal=()=>{
		dispatch({
			type:ORDER_MODAL,
			payload:true
		})
	}
	const addToCart=(item)=>{
		cart.push({...item})
		dispatch({
			type:CART,
			payload:cart
		})
		return true;
	}
	const updateCart=(data=null)=>{
		dispatch({
			type:CART,
			payload:data?data:cart

		})
	}
	const updateComment=(comment)=>{
		dispatch({
			type:SET_COMMENT,
			payload:comment
		})
	}
	const updateOrder=()=>{
		dispatch({
			type:UPDATE_ORDERS,
			payload:orders
		})
	}
	const deleteOrder = (item,index)=>{
		return new Promise(resolve => {
			API.Order.DeleteOrder(item).then(response=>{
				console.log(response)
				if(response.status){
					orders.splice(index,1);
					updateOrder();
					resolve(response)
				}else{
					resolve(response)
				}
			})
		})

	}
	const orderDetails=({id,loader})=>{
		return API.Order.getOrderDetails({id,loader})
	}
	return {cart,spaces,orders,getSpaces,getOrders,createOrder,openModal,closeModal,addToCart,updateCart,deleteOrder,orderModal,updateComment,orderDetails}
}
