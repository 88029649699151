const Config={
	IMAGE_URI:"https://static.beertime.ge/",
	MAIN:{
		SLIDES:'/api/{lang}/service/slides'
	},
	GUEST:{
		CHECK:"/api/{lang}/guest/check",
		SIGN_IN:"/api/{lang}/guest/signIn",
		SIGN_UP:"/api/{lang}/guest/signUp",
		RECOVER_PASSWORD:"/api/{lang}/guest/resetPasswordCode",
		CHANGE_PASSWORD:"/api/{lang}/guest/changePassword",
		SET_LANGUAGE:"/api/{lang}/guest/language",
	},
	USER:{
		SIGN_OUT:"/api/{lang}/user/signOut",
		REMOVE_FAVOURITE:"/api/{lang}/user/removeFavourite?id={id}",
		ADD_FAVOURITE:"/api/{lang}/user/addFavourite?id={id}",
		UPDATE:"/api/{lang}/user/update",
		UPDATE_PASSWORD:"/api/{lang}/user/updatePassword",
		NOTIFICATIONS:"/api/{lang}/user/notifications",
		READ_NOTIFICATION:"/api/{lang}/user/readNotification?id={id}",
		DELETE_NOTIFICATION:"/api/{lang}/user/deleteNotification?id={id}",
		ORDERS:"/api/{lang}/user/orders",
		CREATE_ORDER:"/api/{lang}/user/createOrder",
		DELETE_ORDER:"/api/{lang}/user/deleteOrder?id={id}",
		UPLOAD_PROFILE_PICTURE:"/api/{lang}/user/uploadProfilePictureWeb",
		MY_CARD:"/api/{lang}/user/myCard",
		MY_BALANCE:"/api/{lang}/user/balance",
		GET_UNREAD_NOTIFICATIONS:"/api/{lang}/user/getUnreadNotificationsCount",
		ORDER_DETAILS:"/api/{lang}/user/orderDetails?id={id}",

	},
	PRODUCT:{
		GET_PRODUCTS:"/api/{lang}/service/products",
		SEARCH:"/api/{lang}/service/search",
		FAVOURITE:"/api/{lang}/service/favourite",
		GET_PRODUCT_BY_ID:"/api/{lang}/service/productById?id={id}",
	},
	ORDER:{
		SPACES:"/api/{lang}/service/spaces"
	}

}
export default Config;