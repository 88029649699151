import "./newCard.scss"
import Config from "../../store/config/Config";
import {useNavigate} from "react-router-dom";
import {Heart} from "../index";
import AddToCart from "../CartIcon/CartIcon";
import {i18n} from "../../store/i18n/i18n";

export const  NewCard =({v,onClick,from="default"})=>{
	const navigate = useNavigate()

	const prize =(money)=> {
		return Number.parseFloat(money).toFixed(2);
	}

	return (
		<div className="card-container" onClick={()=>navigate(`/${i18n.language}/product/${from==="favourite"?v.product_id:v.id}`)}>
			<div className="product-block">
				{/*<span className="onsale">Sale!</span>*/}
				<div>
					<span className="heart"><Heart {...v} onClick={onClick}/></span>
					<div className="product-transition">
						{/*<button>Add to wishlist</button>*/}
						<div className="product-image" style={{background:`#ccc url(${Config.IMAGE_URI+v?.image})`}}/>

					</div>
				</div>

				<div className="product-caption">
					<h3><a href="#">{v.product_name}</a></h3>
					{/*<div className="short-description"> {v?.product_description}</div>*/}
					<span className="price">
{/*
					<del><span>12.63 ₾</span></del>
*/}
					<span className="last-price">{prize(v.product_price)} ₾</span>
						 <AddToCart item={v}/>
				</span>
				</div>

			</div>

		</div>

	)
}