import { UseEvent } from './index';

export default function UseOTP(){
  const event  = UseEvent();
  const open=(params={})=>{
    event.emit("event",{
      type:"OTP",
      ...params
    })
  }

  const close = ()=>{

  }

  return  {open,close}
}
