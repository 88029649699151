import React from 'react'

import {useOrder} from "../../store/hooks/useOrder";
const CartIcon =()=> {
	return(
		<svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px" fill-rule="evenodd"><path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>	)
}
const AddToCart = ({item}) =>{
	const {cart,addToCart,updateCart} = useOrder()

	return <i className={`cart ${cart.map(v=>v.product_id).indexOf(item.product_id)>-1?'active':''}`}  onClick={e=>{
			e.stopPropagation()

			if(cart.map(v=>v.product_id).indexOf(item.product_id)>-1){
				updateCart(cart.filter(v=>v.product_id !==item.product_id));
			}else{
				item["qty"]=1;
				item["sumPrice"]= (item.product_price * item.qty).toFixed(2);
				addToCart(item)
			}
	}}> {CartIcon()}</i>

}
export default AddToCart;