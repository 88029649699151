function isValidEmail(value) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

function validateEmail(value, setEmailError) {
    if (value === "") {
        setEmailError("")
    }
    else if (isValidEmail(value)) {
        setEmailError("")
    }
    else {
        setEmailError("Invalid Email")
    }
}
function validatePhone(value, setPhoneError) {
    if (value.length !== 9 || isNaN(value)) {
        setPhoneError("გთხოვთ, შეამოწმოთ ველი")
    } else {
        setPhoneError("")
    }
}
function validatePassword(value, setPasswordError) {
    if (value.length < 8) {
        setPasswordError("აუცილებელია მინუმიმ 8 სიმბოლო")
    } else {
        setPasswordError("")
    }
}
function validateEmpty(value, setPasswordError) {
    if (value.length ===0) {
        setPasswordError("გთხოვთ, შეამოწმოთ ველი")
    } else {
        setPasswordError("")
    }
}
function validatePasswords(value,value1,setRePasswordError){
    console.log(value,value1)
    if(value.length<8 || value1 !==value){
        setRePasswordError("პაროლები არ ემთხვევა")
    }else {
        setRePasswordError("")
    }
}
const validateOtp = (value,setOtpError) =>{
    if(value.length !==4){
        setOtpError("გთხოვთ, შეამოწმოთ ველი")
    }else{
        setOtpError("")
    }
}
const utils = {
    isValidEmail,
    validateEmail,
    validatePassword,
    validatePhone,
    validateEmpty,
    validatePasswords,
    validateOtp
};

export default utils;