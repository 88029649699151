import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {UseEvent} from "../../store/hooks";
import {SignInModal} from "../modal/signIn/SignInModal";
import {OTPModal} from "../modal/OTP/OTPModal";
import {SignUpModal} from "../modal/signUp/SignInModal";
import {RecoverPasswordModal} from "../modal/recover/RecoverPasswordModal";
import {ChangePasswordModal} from "../modal/ChangePasswordModal/ChangePasswordModal";
import {ReservationModal} from "../modal/ReservationModal/ReservationModal";
import {CartModal} from "../modal/CartModal/CartModal";

const eventRoot = document.getElementById('events');
let counter = 0;
const Events = () => {
	const event = UseEvent();
	const [eventMap, setEventMap] = useState([]);
	useEffect(() => {
		const listener = event.subscribe('event', eventHandler);
		return () => {
			listener.unsubscribe();
		};
	}, []);
	const eventHandler = (e) => {
		let timestamp = new Date().getTime();
		counter += 1;
		eventMap.push({
			key: timestamp,
			sort: counter,
			component: componentHandler({ event: e, key: timestamp }),
		});
		setEventMap([...eventMap]);
	};
	const componentHandler = ({ event, key }) => {
		switch (event.type) {
			case 'OTP':
				return (
					<OTPModal
						onSuccess={event?.onSuccess}
						onResend={event?.onResend}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case 'SignIn':
				return (
					<SignInModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case 'SignUp':
				return (
					<SignUpModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case 'Recovery':
				return (
					<ChangePasswordModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						recoveryType={event?.recoveryType}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case "RecoverPasswordModal":
				console.log("recover")
				return (
					<RecoverPasswordModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case "Reservation":

				return (
					<ReservationModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);
			case "CartModal":
				console.log('cartmodal')
				return (
					<CartModal
						onSuccess={event?.onSuccess}
						key={key}
						id={counter}
						onCancel={() => {
							let findIndex = eventMap.findIndex((v) => v.key === key);
							eventMap.splice(findIndex, 1);
							setEventMap([...eventMap]);
						}}
					/>
				);

		}
		return null;
	};
	return ReactDOM.createPortal(
		eventMap.map((v) => v.component),
		eventRoot
	);
};
export default Events;