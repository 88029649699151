import axios from 'axios';
import UseEvent from "./hooks/useEvent";
const loaders = UseEvent();

const client =  axios.create({
	baseURL: '',
	timeout:50000,

})
class Client {

	static get({url,loader,headers}){
		return new Promise((resolve) => {
			if (loader) this.setLoader(loader, true);
			const lang  = localStorage.getItem("i18nextLng")|| 'ka';
			client.get(url.replace("{lang}",lang)).then(response=>{
				resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
			}).catch(reason => {
				resolve({ status:false , data:reason.response.data})

			}).finally(()=>{
				if (loader) this.setLoader(loader, false);
			})
		})

	}
	static post({url,data,loader,headers}){
		return  new Promise(resolve => {
			if (loader) this.setLoader(loader, true);
			const lang  = localStorage.getItem("i18nextLng")|| 'ka';

			client.post(url.replace("{lang}",lang),data).then(response=>{
				resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
			}).catch(reason =>{
				resolve({ status:false , data:reason.response.data})
			}).finally(()=>{
				if (loader) this.setLoader(loader, false);
			})
		})

	}
	static put({url,data,loader,headers}){
		return  new Promise(resolve => {
			if (loader) this.setLoader(loader, true);
			const lang  = localStorage.getItem("i18nextLng")|| 'ka';

			client.put(url.replace("{lang}",lang),data).then(response=>{
				resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
			}).catch(reason =>{
				resolve({ status:false , data:reason.response.data})
			}).finally(()=>{
				if (loader) this.setLoader(loader, false);
			})
		})

	}
	static setLoader(loader, status) {
		if (typeof loader === "string") {
			loaders.emit(loader, status);
		} else {
			try {
				loader(status);
			} catch (e) {
				console.info(e.message);
			}
		}
	}

}
export default Client;
