
import { toast } from 'react-toastify';

class Toast {
	static show({
		type="error",
		text1,
		autoHide=true,
		visibilityTime=2000
	}){

		if(type==="error"){
			toast.error(text1, {
				position: "top-right",
				autoClose: visibilityTime,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}else{
			toast.success(text1, {
				position: "top-right",
				autoClose: visibilityTime,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}

	}
}
export default Toast