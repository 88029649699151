import React, {useState} from 'react';
import './horizontalMenu.scss';

import {useProduct} from "../../store/hooks/useProduct";

import _ from "lodash";
import {useNavigate} from "react-router-dom";
import Config from "../../store/config/Config";
import {i18n} from "../../store/i18n/i18n";
import {Navigation, Pagination} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"

const HorizontalMenu = ({products}) =>{
    const navigate = useNavigate()
    const {Products,getProducts,setSelectedProducts,SelectedProducts} = useProduct()

    return (

            <div className='horizontal-menu'>
                <div className="mw hm-box">
                    <div className="menu-slider">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={"auto"}
                            centeredSlides={false}
                            navigation={true}
                            slidesPerGroupSkip={2}
                            //pagination={{
                            //    clickable: true,
                            //}}
                            //navigation={true}

                            modules={[ Pagination,Navigation]}
                            breakpoints={{
                                200: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                300: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                440: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                640: {
                                    slidesPerView: 6,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 7,
                                    spaceBetween: 30,
                                },
                                1000: {
                                    slidesPerView: 8,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 10,
                                    spaceBetween: 30,
                                },
                            }}

                        >
                            {
                                _.map(products, (v,k)=> {
                                    return (
                                        <SwiperSlide key={k}>
                                            <div className="menu-item" onClick={()=>{
                                                setSelectedProducts(v)
                                                navigate(`/${i18n.language}/products/${v.id}`)
                                            }}>
                                                <div className="img_tag"><img alt="combo" src={Config.IMAGE_URI+v?.icon}/></div>
                                                <div className="title"><span>{v?.title}</span></div>
                                            </div>

                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>
                    </div>
                    {/*<ul>
                        {
                            _.map(products, (v,k)=> <li key={k} onClick={()=>{
                                setSelectedProducts(v)
                                navigate(`/${i18n.language}/products/${v.id}`)
                            }}>
                                <div className="img_tag"><img alt="combo" src={Config.IMAGE_URI+v?.icon}/></div>
                                <div className="title"><span>{v?.title}</span></div>
                            </li>)
                        }
                    </ul>*/}
                </div>
            </div>
    )
}

export default HorizontalMenu;
