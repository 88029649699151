import { UseEvent } from "./index";

const UseHeader = () => {
  const ev = UseEvent();
  let listener;
  const addListener =(callback)=>{
    listener = ev.subscribe("header", callback);
  }

  const show =()=>{
    ev.emit("header",true)
  }
  const hide =(callback)=>{
    ev.emit("header",false)

  }

  const unsubscribe =()=>{
    listener.unsubscribe();
  }



  return { addListener, unsubscribe,hide,show };
};
export default UseHeader;
