import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import {useTranslation,withTranslation} from "react-i18next";

i18n
	.use(ChainedBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({

		saveMissing: true,
		whitelist:["ka","ru","en"],
		fallbackLng: ["ka","ru","en"],
		debug: false,
		lng: localStorage.getItem('i18nextLng')||'ka',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		ns: ["main"],
		defaultNS: "main",
		react: {
			bindI18n: 'languageChanged',
			bindI18nStore: 'lang',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i','!',"'","."],
			useSuspense: true,
		},
		backend: {
			backends: [
				HttpBackend,
			],
			backendOptions: [{
				loadPath: "/api/{{lng}}/locale/data?lang={{lng}}&ns={{ns}}",
				addPath: '/api/{{lng}}/locale/set',
			}]
		}
	});
export {
	i18n,
	withTranslation,
	useTranslation
}